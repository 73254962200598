import Button from 'react-bootstrap/Button';
import PlusIcon from "../assets/icons/Plus";

const AddNewButton = ({ name = 'Add New', handleClick }) => {
    return (
        <>
            <Button variant="-secondary" className='btn--addNew ms-auto' onClick={handleClick}>
                <PlusIcon size={18} color={"#FFF"} />
                <span className='d-none d-sm-inline ms-1'>{name}</span>
            </Button>
        </>
    )
}

export default AddNewButton;