import { isTokenExpired } from '../utils/auth';

const RequireAuth = ({ children }) => {
    const location = window.location.pathname;
    const isExpired = isTokenExpired();

    if (location === '/forgot-password' || location.includes('reset-password')) {
        return children;
    }

    if (isExpired && location !== '/login') {
        window.location.href = "/login";
    } else if (!isExpired && location === '/login') {
        window.location.href = "/";
    } else {
        return children;
    }
};

export default RequireAuth;