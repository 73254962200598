import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';
import CalendarBlankIcon from '../assets/icons/CalendarBlank'

const ReactDatePicker = ({ lable, placeholder, minDate, dateState }) => {
    const datePickerRef = useRef(null);
    const handleIconClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setFocus();
        }
    };

    return (
        <>
            <Form className='ca-datepicker'>
                <Form.Group className='d-flex align-items-center position-relative'>
                    <Form.Label className={`text-nowrap mb-0 ${lable ? 'me-2' : ''}`}>{lable}</Form.Label>
                    <DatePicker
                        ref={datePickerRef}
                        selected={dateState.selectedDate}
                        onChange={(date) => dateState.setSelectedDate(date)}
                        className="form-control cursor-pointer"
                        dateFormat="MM/dd/YYYY"
                        placeholderText={placeholder}
                        minDate={minDate}
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()}
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={25}
                        scrollableYearDropdown
                    />
                    <div className="icon cursor-pointer" onClick={handleIconClick}>
                        <CalendarBlankIcon size={20} />
                    </div>
                </Form.Group>
            </Form>
        </>
    );
};

export default ReactDatePicker;