import { useEffect, useMemo, useState } from "react";
import { defaultPreviousWeekDates } from "../../../utils/helpers";
import { CustomerLocationFilters, Loader, ReactTable, ToastNotification } from "../../../components";
import { useMyLocationsMutation } from "../myLocations/api/myLocationsApi";
import { useMachineReportMutation } from "../../admin/locationMachines/api/locationMachinesApi";
import { DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { extractDate, formatDollarNumber, downloadBlobFile } from "../../../utils/helpers";
import FileCSVIcon from "../../../assets/icons/FileCSV";

const MachineReportPage = () => {
    const columns = useMemo(() => [
        { header: 'MACHINE NAME', accessorKey: 'machine_name' },
        { header: 'LOCATION NAME', accessorKey: 'location_name' },
        {
            header: 'TOTAL REVENUE', accessorKey: 'revenue',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'CASH IN', accessorKey: 'cash_in',
            cell: ({ row }) => formatDollarNumber(row.original.cash_in)
        },
        {
            header: 'CASH OUT', accessorKey: 'cash_out',
            cell: ({ row }) => formatDollarNumber(row.original.cash_out)
        }
    ], []);
    const defaultDates = useMemo(() => defaultPreviousWeekDates(), []);
    const [dateRange, setDateRange] = useState([defaultDates.startDate, defaultDates.endDate]);
    const [locationsList, setLocationsList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [machineData, setMachineData] = useState([]);
    const [totalsData, setTotalsData] = useState({});
    const [allMachineCount, setAllMachineCount] = useState(0);
    const [toastMessage, setToastMessage] = useState({});
    const [selectedLocation, setSelectedLocation] = useState({ value: '', label: 'All Location' });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [myLocationsApi] = useMyLocationsMutation();
    const [machineReportApi] = useMachineReportMutation();

    const fetchMachineReport = async (isExport) => {
        try {
            setShowLoader(true);
            const extractedDate = extractDate(dateRange[0], dateRange[1]);
            const machineReport = await machineReportApi({
                id: selectedLocation.value,
                page_no: pagination.pageIndex + 1,
                limit: DEFAULT_PAGE_SIZE,
                start_date: extractedDate.startDate,
                end_date: extractedDate.endDate,
                isExport
            }).unwrap();

            if (machineReport?.type === 'text/csv') {
                downloadBlobFile(machineReport, 'machine-report.csv');
            } else {
                if (machineReport?.data?.results) {
                    setMachineData(machineReport.data.results);
                    setAllMachineCount(machineReport.data.count);
                    if (machineReport.data?.totals?.revenue) {
                        const { cash_in, cash_out, revenue } = machineReport.data.totals;
                        setTotalsData({
                            machine_name: "Totals",
                            cash_in: `$${cash_in}`,
                            cash_out: `$${cash_out}`,
                            revenue: `$${revenue}`,
                        });
                    }
                }
            }
        } catch (error) {
            setMachineData([]);
            setAllMachineCount(0);
        }
        setShowLoader(false);
    }

    const fetchLocations = async () => {
        try {
            const myLocations = await myLocationsApi({ page: '' }).unwrap();
            if (myLocations?.data?.locations?.length) {
                const locationsArrList = myLocations.data.locations.map(cp => ({ value: cp.id, label: cp.name }));
                locationsArrList.sort((a, b) => a.label.localeCompare(b.label));
                locationsArrList.unshift({ value: '', label: 'All Location' });
                setLocationsList(locationsArrList);
            }
        } catch (error) { }
    }

    useEffect(() => {
        fetchLocations();
    }, []);
    useEffect(() => {
        fetchMachineReport();
    }, [pagination]);

    const handleCsvDownload = () => {
        if (!machineData?.length) {
            setToastMessage({
                message: "No records available for export.",
                variant: "error"
            });
            return;
        }
        fetchMachineReport(true);
    }

    return (
        <div className="content__wrapper content__wrapper--bgwhite content__wrapper--spacing">
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className='layout bg-white mb-3'>
                <div className="layout__subheader d-flex flex-wrap align-items-center">
                    <h3 className="layout__subheader--title fs-5 me-3 my-1">
                        Machine Report
                    </h3>
                    <div className='d-inline-flex ms-auto my-1'>
                        <span>Export :</span>
                        <figure className='ms-1 mb-0 cursor-pointer' onClick={handleCsvDownload}>
                            <FileCSVIcon />
                        </figure>
                    </div>
                </div>
                <div className="mx-0">
                    <hr className='border border-dark-subtle mt-2' />
                </div>
                <div className="layout__body mt-3">
                    <CustomerLocationFilters
                        dateRangeState={{
                            dateRange, setDateRange
                        }}
                        listData={{
                            data: locationsList,
                            title: "Select Location"
                        }}
                        selectedListState={{
                            selectedListData: selectedLocation,
                            setSelectedListData: setSelectedLocation
                        }}
                        onApply={() => setPagination({ ...pagination, pageIndex: 0 })}
                    />
                    <div className="position-relative">
                        {showLoader && <Loader />}
                        <ReactTable
                            columns={columns}
                            data={machineData}
                            pageCount={Math.ceil(allMachineCount / DEFAULT_PAGE_SIZE)}
                            paginationState={{ pagination, setPagination }}
                            totals={totalsData}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MachineReportPage;