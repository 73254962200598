import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
    name: 'customers',
    initialState: {
        list: [],
        totalRecords: 0
    },
    reducers: {
        setCustomersList: (state, action) => {
            const { customersList, totalRecords } = action.payload;
            state.list = customersList;
            state.totalRecords = totalRecords;
        }
    }
})

export const { setCustomersList } = customersSlice.actions;

export default customersSlice.reducer;