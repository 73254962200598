export const DEFAULT_PAGE_SIZE = 10;

export const ADMIN_ROLE = 'ADMIN';

export const USER_ROLE = 'USER';

export const dateOptions = { day: '2-digit', month: 'long', year: 'numeric' };

export const chartLabelDateOptions = { day: '2-digit', month: 'short', year: '2-digit' };

export const chartDatasetsOptions = {
    borderColor: 'rgb(255,255,255)',
    barThickness: 15,
    borderWidth: 4
}

export const defaultAdminChartData = {
    labels: ['No Data Found'],
    datasets: [{ label: 'Profit' }, { label: 'Total revenue' }]
}

export const LICENSE_IMAGE = {
    MIN_SIZE: 50 * 1024, // 50KB
    MAX_SIZE: 5 * 1024 * 1024 // 5MB
}