import Select from 'react-select';
import { Col, Row, Button } from 'react-bootstrap';
import { ReactRangeDatePicker } from '../components';

const CustomerLocationFilters = ({ dateRangeState, listData, selectedListState, onApply }) => {
    return (
        <Row className='flex-wrap flex-column flex-lg-row align-items-lg-center mb-3'>
            <Col md={7} lg={5} xl={5} className='mb-3 mb-lg-2 mb-xl-0'>
                <div className='d-flex align-items-center'>
                    <span className='form-label text-nowrap me-2 mb-0'>{listData.title}</span>
                    <Select
                        options={listData.data}
                        defaultValue={selectedListState.selectedListData}
                        onChange={(selectedOption) => selectedListState.setSelectedListData(selectedOption)}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </div>
            </Col>
            <Col md={7} lg={7} xl={5} className='mb-3 mb-lg-2 mb-xl-0'>
                <ReactRangeDatePicker
                    lable="Select Date Range"
                    placeholder="Select Date Range"
                    dateRange={dateRangeState.dateRange}
                    setDateRange={dateRangeState.setDateRange}
                />
            </Col>
            <Col>
                <Button variant="primary-outline" className="d-inline-flex" onClick={() => onApply()}>
                    Apply
                </Button>
            </Col>
        </Row>
    )
}

export default CustomerLocationFilters;