import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { AddNewButton, SearchBox, ReactTable, Loader, ToastNotification } from "../../../components";
import { AddNewCompany, DeleteCompany, EditCompany } from "./components";
import { useListCompanyMutation } from "./api/companyApi";
import { setCompanyList } from "./slices/companySlice";
import { DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import PencilIcon from "../../../assets/icons/Pencil";
import TrashIcon from "../../../assets/icons/Trash";

const CompanyPage = () => {
    const columns = useMemo(() => [
        { header: 'NAME', accessorKey: 'name', },
        { header: 'LICENSE NUMBER', accessorKey: 'license_number', },
        { header: 'CODE', accessorKey: 'code', }
    ], []);
    const actionMenu = useMemo(() => [
        {
            name: "Edit",
            action: () => {
                setEditModal(true);
            },
            icon: <PencilIcon size={18} />
        },
        {
            name: "Delete",
            action: () => {
                setDeleteModal(true);
            },
            icon: <TrashIcon size={18} />
        }
    ], []);
    const [searchCompany, setSearchCompany] = useState(null);
    const [addNewModal, setAddNewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [toastMessage, setToastMessage] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [listCompanyApi] = useListCompanyMutation();
    const dispatch = useDispatch();
    const companySlice = useSelector((state) => state.company);

    const fetchCompanyList = async () => {
        try {
            setShowLoader(true);
            const companies = await listCompanyApi({
                page: pagination.pageIndex + 1,
                search: searchCompany || ""
            }).unwrap();
            const companiesData = companies?.data;
            if (companiesData?.companies) {
                dispatch(setCompanyList({
                    companyList: companiesData.companies,
                    totalRecords: companiesData.count
                }));
            }
        } catch (error) {
            dispatch(setCompanyList({
                companyList: [],
                totalRecords: 0
            }));
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchCompanyList();
    }, [pagination]);

    useEffect(() => {
        if (searchCompany !== null) {
            const searchDebouncing = setTimeout(() => {
                setPagination({
                    ...pagination,
                    pageIndex: 0
                });
            }, 1000);

            return () => clearTimeout(searchDebouncing);
        }
    }, [searchCompany]);

    const applySearchFilter = (e) => {
        setSearchCompany(e.target.value);
    }

    return (
        <>
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className="content__wrapper content__wrapper--bgwhite content__wrapper--spacing">
                <div className="layout layout-company">
                    {/* Company title and add new button */}
                    <div className="layout__header d-flex flex-wrap justify-content-between align-items-center mb-3">
                        <h2 className="title mb-0">Company</h2>
                        <AddNewButton handleClick={() => setAddNewModal(true)} />
                    </div>
                    <div className="row">
                        <hr />
                    </div>
                    {/* Total Company and search bar */}
                    <div className="layout__subheader d-flex flex-wrap justify-content-end align-items-center mb-3">
                        <p className="layout__subheader--title my-1 me-2">
                            Total Company {companySlice?.totalRecords || 0}
                        </p>
                        <SearchBox
                            placeholder="Search Company"
                            value={searchCompany}
                            handleChange={applySearchFilter}
                        />
                    </div>

                    {/* Table for list of companies */}
                    <div className="layout__body position-relative h-100">
                        {showLoader && <Loader />}
                        <ReactTable
                            columns={columns}
                            data={companySlice.list}
                            pageCount={Math.ceil(companySlice.totalRecords / DEFAULT_PAGE_SIZE)}
                            paginationState={{ pagination, setPagination }}
                            actionMenu={actionMenu}
                            setSelectedRow={setSelectedRow}
                        />
                    </div>
                </div>

                {/* Add New Company Modal */}
                {addNewModal &&
                    <AddNewCompany
                        onClose={() => setAddNewModal(false)}
                        paginationState={{ pagination, setPagination }}
                        setToastMessage={setToastMessage}
                    />
                }

                {/* Delete Company Modal */}
                {deleteModal &&
                    <DeleteCompany
                        selectedRow={selectedRow}
                        onClose={() => setDeleteModal(false)}
                        paginationState={{ pagination, setPagination }}
                        setToastMessage={setToastMessage}
                    />
                }

                {/* Edit Company Modal */}
                {editModal &&
                    <EditCompany
                        selectedRow={selectedRow}
                        onClose={() => setEditModal(false)}
                        paginationState={{ pagination, setPagination }}
                        setToastMessage={setToastMessage}
                    />
                }
            </div>
        </>
    )
}

export default CompanyPage;