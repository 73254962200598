import * as React from "react";

const Bell = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 32;
    const defaultHeight = 32;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M28.161 20.741C27.076 18.875 26.5 16.196 26.5 13a10.5 10.5 0 1 0-21 0c0 3.198-.574 5.875-1.659 7.741a2.51 2.51 0 0 0 .905 3.429c.381.22.814.333 1.254.33h4.523a5.5 5.5 0 0 0 10.954 0H26a2.472 2.472 0 0 0 2.164-1.25 2.51 2.51 0 0 0-.003-2.509ZM16 26.5a2.5 2.5 0 0 1-2.45-2h4.9a2.5 2.5 0 0 1-2.45 2Zm-9.167-5C7.939 19.25 8.5 16.392 8.5 13a7.5 7.5 0 0 1 15 0c0 3.391.56 6.25 1.666 8.5H6.833Z"
            />
        </svg>
    );
}

export default Bell;