import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        changePassword: builder.mutation({
            query: (data) => ({
                url: 'customer/change-password',
                method: 'PATCH',
                body: data
            })
        }),
        editProfile: builder.mutation({
            query: (data) => ({
                url: 'customer/update-profile',
                method: 'PATCH',
                body: data
            })
        }),
        profileInfo: builder.mutation({
            query: () => ({
                url: 'customer/profile',
                method: 'GET'
            })
        })
    })
})

export const { useChangePasswordMutation, useEditProfileMutation, useProfileInfoMutation } = profileApi;