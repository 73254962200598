import { Button, Modal, ListGroup } from 'react-bootstrap';
import XCircleFillIcon from '../../../../assets/icons/XCircleFill';
import { usDateFormat, generateContractEndDate, stringToDate } from '../../../../utils/helpers';

const ViewCustomerLocation = ({ selectedRow, onClose }) => {
    const { name, place_of_issue, license_number, code, address, contract_duration, contract_start_date } = selectedRow;
    const locationInformation = [
        {
            title: "Place Of Issue",
            value: place_of_issue,
            class: "border-top-0"
        },
        {
            title: "Location Name",
            value: name
        },
        {
            title: "License Number",
            value: license_number
        },
        {
            title: "Code",
            value: code
        },
        {
            title: "Address",
            value: address
        },
        {
            title: "Contract Start Date",
            value: usDateFormat(contract_start_date)
        },
        {
            title: "Contract Duration (Months)",
            value: contract_duration
        },
        {
            title: "Contract End Date",
            value: generateContractEndDate(stringToDate(contract_start_date), contract_duration),
            class: "border-bottom-0"
        }
    ]

    return (
        <>
            <Modal show={true} onHide={onClose}>
                <Modal.Header>
                    <Modal.Title className='title'>Location Information</Modal.Title>
                    <Button variant="basic" className='ms-auto px-0' onClick={onClose}><XCircleFillIcon size={24} /></Button>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup className='border-start-0 border-end-0'>
                        {
                            locationInformation.map((li) => {
                                return (
                                    <ListGroup.Item className={`border-start-0 border-end-0 ${li.class}`}>
                                        <p className='item-title quaternary-text fw-semibold fs-6 mb-0'>
                                            {li.title}
                                        </p>
                                        <p className='item-info mb-0'>
                                            {li.value}
                                        </p>
                                    </ListGroup.Item>
                                );
                            })
                        }
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewCustomerLocation;