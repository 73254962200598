const SearchBox = ({ placeholder, value, handleChange }) => {
    return (
        <>
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                className="form-control search-input-box ms-auto my-1"
                style={{
                    background: `url('/images/MagnifyingGlass.svg') no-repeat right 10px center`
                }}
            />
        </>
    )
}

export default SearchBox;