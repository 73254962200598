import { useState, lazy } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Button, Offcanvas, Collapse, ListGroup } from 'react-bootstrap';
const GaugeIcon = lazy(() => import("../assets/icons/Gauge"));
const Buildings = lazy(() => import("../assets/icons/Buildings"));
const UsersThree = lazy(() => import("../assets/icons/UsersThree"));
const Upload = lazy(() => import("../assets/icons/Upload"));
const ClipboardText = lazy(() => import("../assets/icons/ClipboardText"));
const CaretDown = lazy(() => import("../assets/icons/CaretDown"));
const CaretRight = lazy(() => import("../assets/icons/CaretRight"));

const AdminSidebar = ({ showMobileSidebar, handleClose }) => {
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => handleClose();

    return (
        <>
            <aside className='sidebar'>
                <Offcanvas show={showMobileSidebar} onHide={handleClose} responsive="lg">
                    <Offcanvas.Header className='pb-0' closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='w-100'>
                        <Nav className='sidebar__menu border-0'>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li">
                                    <NavLink to='/' className='list-group-item-link' onClick={handleLinkClick}>
                                        <figure className="link-icon-wrapper m-0">
                                            <GaugeIcon size={24} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>Dashboard</span>
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <NavLink to='/company' className='list-group-item-link' onClick={handleLinkClick}>
                                        <figure className="link-icon-wrapper m-0">
                                            <Buildings size={24} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>Company</span>
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <NavLink to='/customers' className='list-group-item-link' onClick={handleLinkClick}>
                                        <figure className="link-icon-wrapper m-0">
                                            <UsersThree size={24} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>Customers</span>
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <NavLink to='/upload' className='list-group-item-link' onClick={handleLinkClick}>
                                        <figure className="link-icon-wrapper m-0">
                                            <Upload size={20} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>Upload Data</span>
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <Button
                                        variant="basic"
                                        className='sidebar__menu--title btn--full-width align-items-baseline p-0'
                                        onClick={() => setOpen(!open)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={open}
                                    >
                                        <figure className="link-icon-wrapper m-0">
                                            <ClipboardText size={18} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>Report</span>
                                        <figure className="link-icon-wrapper m-0 caret-down-icon ms-auto">
                                            <CaretDown size={13} />
                                        </figure>
                                    </Button>
                                    <Collapse in={open}>
                                        <div id="example-collapse-text">
                                            <ListGroup as="ul" className='sidebar__menu--innerlist ms-2 p-0 pt-2'>
                                                <ListGroup.Item as="li" className='innerlist-title p-1'>
                                                    <NavLink to='/report/income' className='list-group-item-link align-items-baseline'>
                                                        <figure className="link-icon-wrapper m-0">
                                                            <CaretRight size={12} />
                                                        </figure>
                                                        <span className='sidebar__menu--title ms-2'>Income Report</span>
                                                    </NavLink>
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" className='innerlist-title p-1'>
                                                    <NavLink to='/report/customer' className='list-group-item-link align-items-baseline'>
                                                        <figure className="link-icon-wrapper m-0">
                                                            <CaretRight size={12} />
                                                        </figure>
                                                        <span className='sidebar__menu--title ms-2'>Customer EFT</span>
                                                    </NavLink>
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" className='innerlist-title p-1'>
                                                    <NavLink to='/report/location' className='list-group-item-link align-items-baseline'>
                                                        <figure className="link-icon-wrapper m-0">
                                                            <CaretRight size={12} />
                                                        </figure>
                                                        <span className='sidebar__menu--title ms-2'>Location EFT</span>
                                                    </NavLink>
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" className='innerlist-title p-1'>
                                                    <NavLink to='/report/daily' className='list-group-item-link align-items-baseline'>
                                                        <figure className="link-icon-wrapper m-0">
                                                            <CaretRight size={12} />
                                                        </figure>
                                                        <span className='sidebar__menu--title ms-2'>Daily Report</span>
                                                    </NavLink>
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" className='innerlist-title p-1'>
                                                    <NavLink to='/report/machine' className='list-group-item-link align-items-baseline'>
                                                        <figure className="link-icon-wrapper m-0">
                                                            <CaretRight size={12} />
                                                        </figure>
                                                        <span className='sidebar__menu--title ms-2'>Machine Report</span>
                                                    </NavLink>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </div>
                                    </Collapse>
                                    {/* <div className='list-group-item-link w-100'>
                                    </div> */}
                                </ListGroup.Item>
                            </ListGroup>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </aside>
        </>
    )
}

export default AdminSidebar;