import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/constants';

export const customersApi = createApi({
    reducerPath: 'customersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        addCustomer: builder.mutation({
            query: (data) => ({
                url: 'customer/add',
                method: 'POST',
                body: data
            })
        }),
        listCustomers: builder.mutation({
            query: (data) => ({
                url: `customer/all${data ? `?page_no=${data.page}&limit=${DEFAULT_PAGE_SIZE}&search=${data.search}` : ''}`,
                method: 'GET'
            })
        }),
        deleteCustomer: builder.mutation({
            query: (data) => ({
                url: `customer/delete/${data.id}`,
                method: 'DELETE'
            })
        }),
        editCustomer: builder.mutation({
            query: (data) => ({
                url: 'customer/edit',
                method: 'PATCH',
                body: data
            })
        }),
        customerCsvImport: builder.mutation({
            query: (data) => ({
                url: 'customer/import-customer',
                method: 'POST',
                body: data
            })
        })
    })
});

export const { useAddCustomerMutation, useListCustomersMutation, useDeleteCustomerMutation, useEditCustomerMutation, useCustomerCsvImportMutation } = customersApi;