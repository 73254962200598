import { useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useVerifyOtpMutation } from '../api/loginApi';
import { Loader, ToastNotification } from '../../../../components';
import { Form, Button } from 'react-bootstrap';
import OtpInput from 'react-otp-input';

const OtpVerificationForm = ({ setIsLoggedIn }) => {
    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const token = useSelector((state) => state.login?.token);
    const [toastMessage, setToastMessage] = useState({
        message: "OTP sent successfully to your email",
        variant: "success"
    });
    const navigate = useNavigate();
    const [verifyOtpApi] = useVerifyOtpMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (otp?.length !== 4) {
                setErrorMessage('');
                setValidationError({ otp: "Please enter 4 digit otp" });
                return;
            }
            if (!token) {
                setIsLoggedIn(false);
                return;
            }
            setShowLoader(true);
            await verifyOtpApi({ otp, token }).unwrap();

            setIsLoggedIn(false);
            return navigate("/");
        } catch (err) {
            setValidationError({});
            setErrorMessage(err?.message || err?.data?.message || err?.data?.error || 'Failed to verify OTP');
            setShowLoader(false);
        }
    };
    return (
        <div className='login__form d-flex flex-column justify-content-center h-100'>
            {
                toastMessage.message &&
                <ToastNotification
                    message={toastMessage.message}
                    variant={toastMessage.variant}
                    onClose={() => setToastMessage({})}
                />
            }
            {showLoader && <Loader />}
            <h2 className='login__form--title fw-light mb-1'>Verification</h2>
            <h3 className='login__form--text mb-4 mt-2'>Enter the 4 digit Number which we sent on the email address</h3>
            {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}

            <Form className='position-relative mb-3' onSubmit={handleSubmit}>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle="otp-input mb-2 d-flex justify-content-between"
                    inputStyle="otp-input__input"
                    shouldAutoFocus={true}
                    inputType="tel"
                />
                {validationError.otp && <p className="error-message position-absolute">{validationError?.otp}</p>}
                <Button variant="-primary" type="submit" className='w-100 mt-5'>
                    Verify
                </Button>
            </Form>
        </div>
    )
}

export default OtpVerificationForm;