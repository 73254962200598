import React from 'react';

const Logo = ({ imageUrl, altText, size = 'medium' }) => {
  return (
    <div className={`logo logo--${size}`}>
      <img src={imageUrl} alt={altText} />
    </div>
  );
}

export default Logo;
