import { createSlice } from '@reduxjs/toolkit';

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        list: [],
        totalRecords: 0
    },
    reducers: {
        setCompanyList: (state, action) => {
            const { companyList, totalRecords } = action.payload;
            state.list = companyList;
            state.totalRecords = totalRecords;
        }
    }
})

export const { setCompanyList } = companySlice.actions;

export default companySlice.reducer;