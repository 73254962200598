import * as React from "react";

const Hourglass = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 12;
    const defaultHeight = 14;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M.938.5h10.124v1.125H9.938v2.25a3.78 3.78 0 0 1-.528 1.951A3.854 3.854 0 0 1 8.021 7.25a3.854 3.854 0 0 1 1.39 1.424 3.78 3.78 0 0 1 .527 1.951v2.25h1.124V14H.938v-1.125h1.125v-2.25c0-.703.17-1.354.51-1.951A3.993 3.993 0 0 1 3.978 7.25a3.993 3.993 0 0 1-1.407-1.424 3.88 3.88 0 0 1-.51-1.951v-2.25H.938V.5Zm2.25 1.125v2.25c0 .516.123.99.369 1.424a3.03 3.03 0 0 0 1.02 1.02c.433.245.907.369 1.423.369a2.74 2.74 0 0 0 1.406-.37 2.834 2.834 0 0 0 1.02-1.02c.258-.433.386-.907.386-1.423v-2.25H3.189ZM3.75 2.75h4.5v1.125c0 .41-.1.791-.299 1.143-.199.34-.474.609-.826.808-.34.2-.715.299-1.125.299-.41 0-.791-.1-1.143-.299a2.23 2.23 0 0 1-.808-.808 2.279 2.279 0 0 1-.299-1.143V2.75ZM6 7.813c-.516 0-.99.128-1.424.386a2.834 2.834 0 0 0-1.02 1.02 2.74 2.74 0 0 0-.369 1.406v2.25h5.626v-2.25c0-.516-.13-.984-.387-1.406a2.663 2.663 0 0 0-1.02-1.02A2.644 2.644 0 0 0 6 7.813Z"
            />
        </svg>
    );
}

export default Hourglass;