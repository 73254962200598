import { createBrowserRouter } from "react-router-dom";
import { Login, HomePage, CompanyPage, CustomersPage, CustomerLocationPage, UploadDataPage, IncomeReportPage, CustomerReportPage, AdminLocationReportPage, LocationMachinesPage, AdminMachineReportPage, DailyReportPage, CustomerLocationMapping } from './features/admin';
import { UserLocationReportPage, UserMachineReportPage, MyLocationsPage } from './features/user';
import { ForgotPasswordPage, ProfilePage, ResetPasswordPage } from './features/common';
import ReportsLayout from './components/ReportsLayout';
import { AppMainLayout } from "./components";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppMainLayout />,
    children: [
      // Admin Routes
      {
        index: true,
        element: <HomePage />
      },
      {
        path: "profile",
        element: <ProfilePage />
      },
      {
        path: "company",
        element: <CompanyPage />
      },
      {
        path: "customers",
        element: <CustomersPage />
      },
      {
        path: "upload",
        element: <UploadDataPage />
      },
      {
        path: "customers/location/:id",
        element: <CustomerLocationPage />
      },
      {
        path: "customer/:customerId/location/:id/machine",
        element: <LocationMachinesPage />
      },
      {
        path: "customer/location-mapping",
        element: <CustomerLocationMapping />
      },
      {
        path: "/report",
        element: <ReportsLayout />,
        children: [
          {
            path: "income",
            element: <IncomeReportPage />
          },
          {
            path: "customer",
            element: <CustomerReportPage />
          },
          {
            path: "location",
            element: <AdminLocationReportPage />
          },
          {
            path: "daily",
            element: <DailyReportPage />
          },
          {
            path: "machine",
            element: <AdminMachineReportPage />
          }
        ]
      },

      // User Routes
      {
        path: "location-report",
        element: <UserLocationReportPage />
      },
      {
        path: "machine-report",
        element: <UserMachineReportPage />
      },
      {
        path: "locations",
        element: <MyLocationsPage />
      }
    ]
  },
  {
    path: "login",
    element: <Login />
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />
  },
  {
    path: "reset-password/:token",
    element: <ResetPasswordPage />
  }
]);

export default router;