import { useState } from 'react';
import { LoginLogo, Loader, ToastNotification } from '../../../components';
import { Row, Col, Form, Button } from 'react-bootstrap';
import ArrowBackIcon from '../../../assets/icons/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordFormValidation } from '../../../utils/formValidation';
import { useResetPasswordMutation } from '../../admin/login/api/loginApi';

const initialFormData = { new_password: '', confirm_password: '' };

const ResetPasswordPage = () => {
    const { token } = useParams();
    const [formData, setFormData] = useState(initialFormData);
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [toastMessage, setToastMessage] = useState({});
    const [resetPasswordApi] = useResetPasswordMutation();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const validationResult = resetPasswordFormValidation({ ...formData, [name]: value });
        if (!validationResult.errorMessage?.[name] && validationError[name]) {
            setErrorMessage('');
            setValidationError({ ...validationError, [name]: "" });
            return;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationResult = resetPasswordFormValidation(formData);
            if (validationResult.errorMessage) {
                setErrorMessage('');
                setValidationError(validationResult.errorMessage);
                return;
            }

            setShowLoader(true);
            await resetPasswordApi({ ...validationResult.data, token }).unwrap();
            setToastMessage({
                message: "Password reset successfully.",
                variant: "success"
            });
            setErrorMessage('');
            setFormData(initialFormData);
        } catch (err) {
            setErrorMessage(err?.message || err?.data?.message || err?.data?.error || 'Reset password request failed');
        }
        setValidationError({});
        setShowLoader(false);
    };

    return (
        <div className='login-wrapper d-flex justify-content-center align-items-center'>
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <Row className='login m-0'>
                <Col lg={6} className='px-0'>
                    <LoginLogo />
                </Col>
                <Col lg={6} className='px-0'>
                    <div className='login__form d-flex flex-column justify-content-center h-100'>
                        {showLoader && <Loader />}

                        <h2 className='login__form--title fw-light mb-3'>Reset Password</h2>
                        <h3 className='login__form--text mb-4'>
                            Enter the new password to reset.
                        </h3>
                        {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}
                        <Form className='mb-3' onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                <Form.Label className='mb-1'>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    id="password"
                                    name="new_password"
                                    placeholder="Enter New Password"
                                    value={formData.new_password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {validationError.new_password && <p className="error-message position-absolute">{validationError.new_password}</p>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confirmPassword">
                                <Form.Label className='mb-1'>Confirm New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    id="confirmPassword"
                                    name="confirm_password"
                                    placeholder="Re-Enter New Password"
                                    value={formData.confirm_password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {validationError.confirm_password && <p className="error-message position-absolute">{validationError.confirm_password}</p>}
                            </Form.Group>
                            <Button variant="-primary" type="submit" className='mt-4 w-100'>
                                Reset Password
                            </Button>
                        </Form>

                        <p className='primary-text text-center'>
                            <span
                                class="text-decoration-underline link-offset-2 cursor-pointer"
                                onClick={() => navigate('/login')}
                            >
                                <ArrowBackIcon size={16} color={"#3547AC"} />
                                <span className='ms-1'>Back To Login</span>
                            </span>
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ResetPasswordPage;