import Card from 'react-bootstrap/Card';

const CustomerInformation = ({ title, value }) => {
    return (
        <Card className='border-0 p-0'>
            <Card.Body className='p-0 pe-2'>
                <Card.Title className='mb-1'>
                    {title}
                </Card.Title>
                <Card.Text
                    className={`${title === 'EMAIL' ? 'primary-text' : ''}`}
                >
                    {value}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default CustomerInformation;