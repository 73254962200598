import { useState, useRef, useEffect } from "react";
import { Button, Form, Modal } from 'react-bootstrap';
import { useEditCompanyMutation } from "../api/companyApi";
import { Loader } from '../../../../components';
import { companyFormValidation } from "../../../../utils/formValidation";
import XCircleFillIcon from '../../../../assets/icons/XCircleFill';

const EditCompany = ({ selectedRow, onClose, paginationState, setToastMessage }) => {
    const { id, name, license_number, code } = selectedRow;
    const [formData, setFormData] = useState({ name, license_number, code });
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const firstInputRef = useRef(null);
    const [editCompanyApi] = useEditCompanyMutation();

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const validationResult = companyFormValidation({ ...formData, [name]: value });
        if (!validationResult.errorMessage?.[name] && validationError[name]) {
            setErrorMessage('');
            setValidationError({ ...validationError, [name]: "" });
            return;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationResult = companyFormValidation(formData);
            if (validationResult.errorMessage) {
                setErrorMessage('');
                setValidationError(validationResult.errorMessage);
                return;
            }

            setShowLoader(true);
            await editCompanyApi({ ...validationResult.data, id }).unwrap();
            paginationState.setPagination({
                ...paginationState.pagination,
            });
            setToastMessage({
                message: "Company updated successfully",
                variant: "success"
            });
            onClose();
        } catch (err) {
            setValidationError({});
            setErrorMessage(err?.data?.message || err?.data?.error || 'Failed to edit this company');
            setShowLoader(false);
        }
    };

    return (
        <>
            <Modal show={true} onHide={onClose}>
                {showLoader && <Loader />}
                <Modal.Header>
                    <Modal.Title className='title'>Edit Company</Modal.Title>
                    <Button variant="basic" className='ms-auto px-0' onClick={onClose}><XCircleFillIcon size={24} /></Button>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}
                    <Form>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Company Name<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter company name"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                ref={firstInputRef}
                            />
                            {validationError.name && <p className="error-message position-absolute">{validationError?.name}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="license_number">
                            <Form.Label>License Number<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="license_number"
                                placeholder="Enter license number"
                                value={formData.license_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.license_number && <p className="error-message position-absolute">{validationError?.license_number}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label>Code<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="code"
                                placeholder="Enter code"
                                value={formData.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.code && <p className="error-message position-absolute">{validationError?.code}</p>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Button variant="-primary" type='submit' onClick={handleSubmit}>
                        Edit
                    </Button>
                    <Button variant="primary-outline" onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditCompany;