import { useState, useMemo, useEffect } from "react";
import { CustomerLocationFilters, Loader, ReactTable, ToastNotification } from "../../../components";
import { defaultPreviousWeekDates, formatDollarNumber, extractDate, downloadBlobFile } from "../../../utils/helpers";
import { DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { useListCustomersMutation } from "../customers/api/customersApi";
import { useMachineReportMutation } from "./api/machineReportApi";
import FileCSVIcon from "../../../assets/icons/FileCSV";

const MachineReportPage = () => {
    const columns = useMemo(() => [
        { header: 'MACHINE NAME', accessorKey: 'machine_name' },
        { header: 'LOCATION NAME', accessorKey: 'name' },
        {
            header: 'TOTAL REVENUE', accessorKey: 'revenue',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'CASH IN', accessorKey: 'cash_in',
            cell: ({ row }) => formatDollarNumber(row.original.cash_in)
        },
        {
            header: 'CASH OUT', accessorKey: 'cash_out',
            cell: ({ row }) => formatDollarNumber(row.original.cash_out)
        }
    ], []);
    const defaultDates = useMemo(() => defaultPreviousWeekDates(), []);
    const [dateRange, setDateRange] = useState([defaultDates.startDate, defaultDates.endDate]);
    const [selectedCustomer, setSelectedCustomer] = useState({ value: '', label: 'All Customer' });
    const [machineData, setMachineData] = useState([]);
    const [machineCount, setMachineCount] = useState(0);
    const [toastMessage, setToastMessage] = useState({});
    const [customersList, setCustomersList] = useState([]);
    const [totalsData, setTotalsData] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [listCustomersApi] = useListCustomersMutation();
    const [machineReportApi] = useMachineReportMutation();

    const fetchCustomers = async () => {
        try {
            const customersList = await listCustomersApi().unwrap();
            const customers = customersList?.data?.customers;
            if (customers?.length) {
                const customersArrList = customers.map(cp => ({
                    value: cp.id,
                    label: `${cp.name} (${cp?.companies?.name})`
                }));

                customersArrList.sort((a, b) => a.label.localeCompare(b.label));
                customersArrList.unshift({ value: '', label: 'All Customer' });
                setCustomersList(customersArrList);
            }
        } catch (error) { }
    }

    const fetchMachineReport = async (isExport) => {
        try {
            setShowLoader(true);
            const extractedDate = extractDate(dateRange[0], dateRange[1]);
            const machineReport = await machineReportApi({
                customer_id: selectedCustomer.value,
                page_no: pagination.pageIndex + 1,
                limit: DEFAULT_PAGE_SIZE,
                from_date: extractedDate.startDate,
                to_date: extractedDate.endDate,
                isExport
            }).unwrap();

            if (machineReport?.type === 'text/csv') {
                downloadBlobFile(machineReport, 'machine-report.csv');
            } else {
                if (machineReport?.data?.results) {
                    setMachineData(machineReport.data.results);
                    setMachineCount(machineReport.data.count);
                    if (machineReport.data?.totals?.revenue) {
                        const { cash_in, cash_out, revenue } = machineReport.data.totals;
                        setTotalsData({
                            machine_name: "Totals",
                            cash_in: `$${cash_in}`,
                            cash_out: `$${cash_out}`,
                            revenue: `$${revenue}`,
                        });
                    }
                }
            }
        } catch (error) {
            setMachineData([]);
            setMachineCount(0);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchCustomers();
    }, []);
    useEffect(() => {
        fetchMachineReport();
    }, [pagination]);

    const handleCsvDownload = () => {
        if (!machineData?.length) {
            setToastMessage({
                message: "No records available for export.",
                variant: "error"
            });
            return;
        }
        fetchMachineReport(true);
    }

    return (
        <>
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className="layout__subheader d-flex flex-wrap align-items-center mt-3">
                <h3 className="layout__subheader--title fs-5 me-3 my-1">
                    Machine Report
                </h3>
                <div className='d-inline-flex ms-auto my-1'>
                    <span>Export :</span>
                    <figure className='ms-1 mb-0 cursor-pointer' onClick={handleCsvDownload}>
                        <FileCSVIcon />
                    </figure>
                </div>
            </div>
            <div className="row mx-0">
                <hr className='border border-dark-subtle mt-2' />
            </div>
            <div className="layout__body mt-3">
                {/* Report filters */}
                <CustomerLocationFilters
                    dateRangeState={{
                        dateRange, setDateRange
                    }}
                    listData={{
                        data: customersList,
                        title: 'Select Customer'
                    }}
                    selectedListState={{
                        selectedListData: selectedCustomer,
                        setSelectedListData: setSelectedCustomer
                    }}
                    onApply={() => setPagination({ ...pagination, pageIndex: 0 })}
                />
                {/* Machine report data table */}
                <div className="position-relative mt-3">
                    {showLoader && <Loader />}
                    <ReactTable
                        data={machineData}
                        columns={columns}
                        pageCount={Math.ceil(machineCount / DEFAULT_PAGE_SIZE)}
                        paginationState={{ pagination, setPagination }}
                        totals={totalsData}
                    />
                </div>
            </div>
        </>
    )
}

export default MachineReportPage;