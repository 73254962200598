import * as React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss'
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import store from './app/store';
import { Provider } from 'react-redux';
import RequireAuth from "./utils/RequireAuth";

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RequireAuth>
        <RouterProvider router={router} />
      </RequireAuth>
    </Provider>
  </React.StrictMode>
);