import React, { lazy } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Button, Dropdown } from 'react-bootstrap';
import Logo from "./Logo";
import logoImage from './../assets/images/logo/logo.svg';
import { useLogoutMutation } from "../features/admin/login/api/loginApi";
import SidebarMenuIcon from './../assets/images/icons/sidebar-menu-icons/SidebarMenuIcon.svg';
import UserIcon from '../assets/icons/User';
import LogoutIcon from '../assets/icons/Logout';
import profileDefaultImage from "../assets/images/others/ProfileDefault.png";
const CaretDown = lazy(() => import("../assets/icons/CaretDown"));


const HeaderLogo = React.memo(() => (
    <Link to='/'>
        <Logo imageUrl={logoImage} altText="Custom Size Logo" size='responsive' />
    </Link>
));
const Ellipse = React.memo(({ name, avatar }) => (
    <>
        <span className="header__profile--username d-none d-sm-inline-block fw-medium me-2">
            Welcome {name}
        </span>
        <span className="header__profile--avatar d-inline-block">
            <img
                src={avatar
                    ? `${process.env.REACT_APP_AVATAR_FILE_PATH}${avatar}`
                    : profileDefaultImage}
                alt="Profile"
                className="object-fit-cover rounded-circle"
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images/Ellipse.png";
                }}
            />
        </span>
        <span className="header__profile--togglericon d-inline-block ms-2">
            <CaretDown size={14} />
        </span>
    </>
));

const Header = ({ handleShow }) => {
    const [logoutApi] = useLogoutMutation();
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.login.user);

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await logoutApi().unwrap();
            return navigate('/login');
        } catch (err) { }
    }

    return (
        <div className="header fixed-top d-flex">
            <div className="header__logo d-flex align-items-center">
                <HeaderLogo />
            </div>
            <div className="header__profile d-flex align-items-center ms-auto">
                <Dropdown align="end">
                    <Dropdown.Toggle variant="basic" id="dropdown-basic">
                        <Ellipse name={userInfo?.name} avatar={userInfo?.avatar} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" className="top-0 end-0">
                        <Dropdown.Item
                            onClick={() => navigate('/profile', {
                                state: { locationPath: location.pathname }
                            })}
                        >
                            <UserIcon size={20} /><span className="ms-2">Profile</span>                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>
                            <LogoutIcon size={20} /> <span className="ms-2">Logout</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="header__menubtn d-flex">
                <Button variant="basic" size="-small" className="d-lg-none align-self-center" onClick={handleShow}>
                    <img src={SidebarMenuIcon} alt="SidebarMenuIcon" />
                </Button>
            </div>
        </div>
    )
}

export default Header;