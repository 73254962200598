import * as React from "react";

const Invoice = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 32;
    const defaultHeight = 32;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M3.5 16a1 1 0 0 1 0-2H7a1 1 0 0 0 0-2H5a3 3 0 0 1 0-6 1 1 0 0 1 2 0h1a1 1 0 0 1 0 2H5a1 1 0 0 0 0 2h2a3 3 0 0 1 0 6 1 1 0 1 1-2 0H3.5ZM28 6H12a1 1 0 1 0 0 2h15v4H13a1 1 0 0 0 0 2h7v4H10a1 1 0 0 0 0 2h10v4H5v-5a1 1 0 1 0-2 0v5a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1Z"
            />
        </svg>
    );
}

export default Invoice;