import * as React from "react";

const FileCSV = (props) => {
    const { size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 26;
    const defaultHeight = 26;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                d="M4.875 18.281c0 .88.558 1.625 1.219 1.625a1.042 1.042 0 0 0 .744-.348A1.22 1.22 0 0 1 8.6 21.244a3.454 3.454 0 0 1-2.505 1.1c-2.016 0-3.657-1.828-3.657-4.063 0-2.234 1.64-4.062 3.657-4.062a3.453 3.453 0 0 1 2.505 1.1 1.219 1.219 0 1 1-1.76 1.686 1.043 1.043 0 0 0-.745-.349c-.661 0-1.219.743-1.219 1.625Zm9.903-.58c-.52-.35-1.15-.532-1.706-.692a8.06 8.06 0 0 1-.803-.263c.249-.12.986-.132 1.632.033a1.219 1.219 0 1 0 .622-2.357 7.02 7.02 0 0 0-1.218-.189c-1.009-.067-1.828.11-2.448.525a2.484 2.484 0 0 0-1.086 1.804c-.111.887.252 1.652 1.027 2.152.486.314 1.052.477 1.6.636.304.09.806.233 1.003.358a.203.203 0 0 1-.022.072c-.138.158-.972.182-1.665-.006a1.22 1.22 0 0 0-.655 2.347c.537.145 1.09.22 1.646.223.657 0 1.395-.12 2.004-.523a2.513 2.513 0 0 0 1.112-1.828c.124-.947-.25-1.76-1.043-2.294v.002Zm7.16-3.413a1.219 1.219 0 0 0-1.554.743L19.5 17.5l-.884-2.468a1.218 1.218 0 1 0-2.295.813l2.031 5.687a1.219 1.219 0 0 0 2.296 0l2.03-5.687a1.219 1.219 0 0 0-.74-1.556Zm-18.282-3.32V4.064A2.031 2.031 0 0 1 5.687 2.03h9.75a1.219 1.219 0 0 1 .863.357l5.687 5.687a1.22 1.22 0 0 1 .357.863v2.03a1.219 1.219 0 0 1-2.438 0v-.405h-4.875a1.218 1.218 0 0 1-1.219-1.22V4.47H6.095v6.5a1.218 1.218 0 1 1-2.438 0ZM16.25 8.126h2.336L16.25 5.789v2.336Z"
            />
        </svg>
    );
}

export default FileCSV;