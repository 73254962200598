import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../api/loginApi';
import { setToken } from '../slices/loginSlice';
import { Loader } from '../../../../components';
import { loginFormValidation } from '../../../../utils/formValidation';
import { Form, Button } from 'react-bootstrap';

const LoginForm = ({ setIsLoggedIn }) => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const firstInputRef = useRef(null);
    const [loginApi] = useLoginMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const validationResult = loginFormValidation({ ...formData, [name]: value });
        if (!validationResult.errorMessage?.[name] && validationError[name]) {
            setErrorMessage('');
            setValidationError({ ...validationError, [name]: "" });
            return;
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationResult = loginFormValidation(formData);
            if (validationResult.errorMessage) {
                setErrorMessage('');
                setValidationError(validationResult.errorMessage);
                return;
            }

            setShowLoader(true);
            const result = await loginApi(validationResult.data).unwrap();
            const token = result?.token;
            if (token) {
                dispatch(setToken({ token }));
                setIsLoggedIn(true);
                return;
            }
        } catch (err) {
            setValidationError({});
            setErrorMessage(err?.message || err?.data?.message || err?.data?.error || 'Login Failed');
        }
        setShowLoader(false);
    };

    return (
        <div className='login__form d-flex flex-column justify-content-center h-100'>
            {showLoader && <Loader />}

            <h2 className='login__form--title fw-light mb-3'>Login</h2>
            <h3 className='login__form--text mb-4'>Please login to continue.</h3>
            {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}

            <Form className='mb-3' onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label className='mb-1'>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        ref={firstInputRef}
                    />
                    {validationError.email && <p className="error-message position-absolute">{validationError?.email}</p>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                    <Form.Label className='mb-1'>Password</Form.Label>
                    <Form.Control
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {validationError.password && <p className="error-message position-absolute">{validationError?.password}</p>}
                </Form.Group>
                <Button variant="-primary" type="submit" className='mt-4 w-100'>
                    Login
                </Button>
            </Form>

            <p className='primary-text text-center'>
                <span
                    class="text-decoration-underline link-offset-2 cursor-pointer"
                    onClick={() => navigate('/forgot-password')}
                >
                    Forgot Password?
                </span>
            </p>
        </div>
    )
}

export default LoginForm;