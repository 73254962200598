import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Loader, AddNewButton, SearchBox, ReactTable, ToastNotification } from "../../../components";
import AddNewCustomer from "./components/AddNewCustomer";
import DeleteCustomer from "./components/DeleteCustomer";
import EditCustomer from "./components/EditCustomer";
import ImportCustomersCsv from "./components/ImportCustomersCsv";
import { useListCustomersMutation } from "./api/customersApi";
import { setCustomersList } from "./slices/customersSlice";
import { DEFAULT_PAGE_SIZE, LICENSE_IMAGE } from "../../../utils/constants";
import MapPinLineIcon from "../../../assets/icons/MapPinLine";
import PencilIcon from "../../../assets/icons/Pencil";
import TrashIcon from "../../../assets/icons/Trash";
import FileArrowUpIcon from "../../../assets/icons/FileArrowUp";

const CustomersPage = () => {
    const columns = useMemo(() => [
        { header: 'COMPANY ASSOCIATE', accessorKey: 'companies.name', },
        {
            header: 'NAME',
            cell: ({ row }) => {
                return (
                    <span className="primary-text">
                        {row.original.name}
                    </span>
                )
            }
        },
        { header: 'EMAIL', accessorKey: 'email', },
        { header: 'PHONE', accessorKey: 'phone', }
    ], []);
    const [selectedRow, setSelectedRow] = useState(null);
    const actionMenu = useMemo(() => [
        {
            name: "View Location",
            action: (event) => {
                event.stopPropagation();
                return navigate(`/customers/location/${selectedRow.id}`);
            },
            icon: <MapPinLineIcon size={18} />
        },
        {
            name: "Edit",
            action: (event) => {
                event.stopPropagation();
                setEditModal(true);
            },
            icon: <PencilIcon size={18} />
        },
        {
            name: "Delete",
            action: (event) => {
                event.stopPropagation();
                setDeleteModal(true);
            },
            icon: <TrashIcon size={18} />
        }
    ], [selectedRow]);
    const [showLoader, setShowLoader] = useState(false);
    const [searchCustomers, setSearchCustomers] = useState(null);
    const [addNewModal, setAddNewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [importCsvModal, SetImportCsvModal] = useState(false);
    const [toastMessage, setToastMessage] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [listCustomersApi] = useListCustomersMutation();
    const customersSlice = useSelector((state) => state.customers);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchCustomersList = async () => {
        try {
            setShowLoader(true);
            const customers = await listCustomersApi({
                page: pagination.pageIndex + 1,
                search: searchCustomers || ""
            }).unwrap();
            const customersData = customers?.data;
            if (customersData?.customers) {
                dispatch(setCustomersList({
                    customersList: customersData.customers,
                    totalRecords: customersData.count
                }));
            }
        } catch (error) {
            dispatch(setCustomersList({
                customersList: [],
                totalRecords: 0
            }));
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchCustomersList();
    }, [pagination]);

    useEffect(() => {
        if (searchCustomers !== null) {
            const searchDebouncing = setTimeout(() => {
                setPagination({
                    ...pagination,
                    pageIndex: 0
                });
            }, 1000);

            return () => clearTimeout(searchDebouncing);
        }
    }, [searchCustomers]);

    const applySearchFilter = (e) => {
        setSearchCustomers(e.target.value);
    }

    return (
        <>
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className="content__wrapper content__wrapper--bgwhite content__wrapper--spacing">
                <div className="layout layout-customers">
                    {/* Customers title and add new button */}
                    <div className="layout__header d-flex flex-wrap justify-content-between align-items-center mb-3">
                        <h2 className="title mb-0">Customers</h2>
                        <div className="d-flex">
                            <Button variant="primary-outline" className="lh-base me-2 px-2 px-sm-3 py-2" onClick={() => SetImportCsvModal(true)}>
                                <FileArrowUpIcon size={18} color={"#3547AC"} />
                                <span className="d-none d-sm-inline ms-1">CSV Import</span>
                            </Button>
                            <AddNewButton handleClick={() => setAddNewModal(true)} />
                        </div>
                    </div>
                    <div className="row">
                        <hr />
                    </div>
                    {/* Total Customers and search bar */}
                    <div className="layout__subheader d-flex flex-wrap justify-content-end align-items-center mb-3">
                        <p className="layout__subheader--title my-1 me-2">
                            Total Customers {customersSlice?.totalRecords || 0}
                        </p>
                        <SearchBox
                            placeholder="Search Customers"
                            value={searchCustomers}
                            handleChange={applySearchFilter}
                        />
                    </div>

                    {/* Table for list of customers */}
                    <div className="layout__body position-relative h-100">
                        {/* Show Loader */}
                        {showLoader && <Loader />}
                        <ReactTable
                            columns={columns}
                            data={customersSlice.list}
                            pageCount={Math.ceil(customersSlice.totalRecords / DEFAULT_PAGE_SIZE)}
                            paginationState={{ pagination, setPagination }}
                            actionMenu={actionMenu}
                            setSelectedRow={setSelectedRow}
                            onClickRedirectRoute={(id) => `/customers/location/${id}`}
                        />
                    </div>
                </div>

                {/* Add New Customer Modal */}
                {addNewModal &&
                    <AddNewCustomer
                        onClose={() => setAddNewModal(false)}
                        paginationState={{ pagination, setPagination }}
                        setToastMessage={setToastMessage}
                        drivingLicense={{
                            maxSize: LICENSE_IMAGE.MAX_SIZE,
                            minSize: LICENSE_IMAGE.MIN_SIZE
                        }}
                    />
                }

                {/* Delete customer Modal */}
                {deleteModal &&
                    <DeleteCustomer
                        selectedRow={selectedRow}
                        onClose={() => setDeleteModal(false)}
                        paginationState={{ pagination, setPagination }}
                        setToastMessage={setToastMessage}
                    />
                }

                {/* Edit Customer Modal */}
                {editModal &&
                    <EditCustomer
                        selectedRow={selectedRow}
                        onClose={() => setEditModal(false)}
                        paginationState={{ pagination, setPagination }}
                        setToastMessage={setToastMessage}
                        drivingLicense={{
                            maxSize: LICENSE_IMAGE.MAX_SIZE,
                            minSize: LICENSE_IMAGE.MIN_SIZE
                        }}
                    />
                }

                {/* Import Customer CSV Modal */}
                {importCsvModal &&
                    <ImportCustomersCsv
                        onClose={() => SetImportCsvModal(false)}
                        setToastMessage={setToastMessage}
                    />
                }
            </div>
        </>
    )
}

export default CustomersPage;