import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const ToastNotification = ({ message, onClose, variant }) => {
    const getVariantClass = () => {
        switch (variant) {
            case 'success':
                return 'success';
            case 'error':
                return 'error';
            case 'warning':
                return 'warning';
            default:
                return 'info'; // Default variant
        }
    };

    return (
        <ToastContainer position="top-end" className="p-3 position-fixed" style={{ zIndex: 9999 }}>
            <Toast show={true} onClose={onClose} className={`toast--${getVariantClass()}`} delay={5000} autohide>
                <Toast.Header className='position-absolute border-0 bg-transparent p-0'>
                </Toast.Header>
                <Toast.Body className='border-0 fw-semibold pe-4'>
                    <div className="toast-body__content d-flex ps-1 rounded-1">
                        <span className='toast-body__message text-break mx-1'>{message}</span>
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default ToastNotification;