import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_PAGE_SIZE } from "../../../../utils/constants";

export const myLocationsApi = createApi({
    reducerPath: 'myLocationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        myLocations: builder.mutation({
            query: (data) => ({
                url: `location/all?page_no=${data.page}&limit=${DEFAULT_PAGE_SIZE}`,
                method: 'GET'
            })
        })
    })
})

export const { useMyLocationsMutation } = myLocationsApi;