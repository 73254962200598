import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Card } from 'react-bootstrap';
import { useEditCustomerMutation } from "../api/customersApi";
import { Loader } from "../../../../components";
import { customerFormValidation } from "../../../../utils/formValidation";
import { useListCompanyMutation } from "../../../admin/company/api/companyApi";
import XCircleFillIcon from '../../../../assets/icons/XCircleFill';
// import TrashIcon from "../../../../assets/icons/Trash";
import Select from 'react-select';
// import FilePDFIcon from "../../../../assets/images/others/PdfDefault.jpeg";
// import { getInitialImageSrc } from "../../../../utils/helpers";
const EditCustomer = ({ selectedRow, onClose, paginationState, setToastMessage, drivingLicense }) => {
    const { id, name, email, phone, company_id, companies, driving_license } = selectedRow;
    // const url = `${process.env.REACT_APP_DRIVING_LICENSE_FILE_PATH}${driving_license}`;
    const [formData, setFormData] = useState({ name, email, phone, company_id });
    // const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [companiesList, setCompaniesList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({ value: company_id, label: companies?.name });
    // const [imageSrc, setImageSrc] = useState(() => getInitialImageSrc(driving_license));
    const [editCustomerApi] = useEditCustomerMutation();
    const [listCompanyApi] = useListCompanyMutation();

    useEffect(() => {
        fetchCompanyList();
    }, []);

    // handle file select
    // const handleFileSelect = async (e) => {
    //     const file = Array.from(e.target.files)[0]
    //     try {
    //         await uploadFiles(file);
    //         if (file && file.type) {
    //             const newImageURL = file.type !== "application/pdf"
    //                 ? URL.createObjectURL(file)
    //                 : FilePDFIcon;
    //             setImageSrc(newImageURL); // Set the new image source
    //         }
    //         e.target.value = '';
    //     } catch (error) { }
    // }

    // const handleBrowseClick = () => document.getElementById('fileInput').click();

    // const uploadFiles = (file) => {
    //     const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']
    //     return new Promise((resolve, reject) => {
    //         if (!allowedTypes.includes(file.type)) {
    //             setToastMessage({
    //                 message: "Only .jpeg, .jpg, .png, and .pdf files are allowed",
    //                 variant: "error"
    //             })
    //             reject();
    //             return;
    //         }

    //         // validate file size
    //         if (file.size > drivingLicense.maxSize || file.size < drivingLicense.minSize) {
    //             setToastMessage({
    //                 message: "File size should be between 50 KB and 5 MB",
    //                 variant: "error"
    //             })
    //             reject();
    //             return;
    //         }
    //         setSelectedFiles([file])
    //         resolve();
    //     })
    // }

    // const handleRemoveLicense = () => {
    //     if (!driving_license || !selectedFiles.length) {
    //         setImageSrc(null)
    //     } else {
    //         setSelectedFiles([])
    //         setImageSrc(url)
    //     }
    // }
    // const handleDownloadLicense = () => {
    //     const fileExtension = driving_license.split(".").pop().toLowerCase();
    //     const link = document.createElement('a');
    //     link.href = (fileExtension === 'pdf') ? url : imageSrc;
    //     link.setAttribute('target', '_blank');
    //     // link.setAttribute('download', `driving_license.${fileExtension}`);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // }
    const fetchCompanyList = async () => {
        try {
            setShowLoader(true);
            const companies = await listCompanyApi({}).unwrap();
            const companiesData = companies?.data;
            if (companiesData?.companies?.length) {
                const companiesArrList = companiesData.companies.map(cp => ({ value: cp.id, label: cp.name }));
                companiesArrList.sort((a, b) => a.label.localeCompare(b.label));
                setCompaniesList(companiesArrList);
            }
        } catch (error) {
            setErrorMessage(error?.data?.message || error?.data?.error || "Failed to fetch list of companies");
        }
        setShowLoader(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const validationResult = customerFormValidation({ ...formData, [name]: value });
        if (!validationResult.errorMessage?.[name] && validationError[name]) {
            setErrorMessage('');
            setValidationError({ ...validationError, [name]: "" });
            return;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            formData.company_id = selectedCompany?.value;
            const validationResult = customerFormValidation(formData);
            if (validationResult.errorMessage) {
                setErrorMessage('');
                setValidationError(validationResult.errorMessage);
                return;
            }

            setShowLoader(true);
            const multipartFormData = new FormData();
            multipartFormData.append('id', id);
            multipartFormData.append('name', validationResult.data.name);
            multipartFormData.append('email', validationResult.data.email);
            multipartFormData.append('phone', validationResult.data.phone);
            multipartFormData.append('company_id', validationResult.data.company_id);
            // if (selectedFiles?.length) {
            //     multipartFormData.append('driving_license', selectedFiles[0]);
            // } else if (driving_license && !imageSrc) {
            //     multipartFormData.append('isDeleteDrivingLicense', true);
            // }
            await editCustomerApi(multipartFormData).unwrap();
            paginationState.setPagination({
                ...paginationState.pagination,
            });
            setToastMessage({
                message: "Customer updated successfully",
                variant: "success"
            });
            onClose();
        } catch (err) {
            setValidationError({});
            setErrorMessage(err?.data?.message || err?.data?.error || 'Failed to edit this customer');
            setShowLoader(false);
        }
    };

    return (
        <>
            <Modal show={true} onHide={onClose}>
                {showLoader && <Loader />}
                <Modal.Header>
                    <Modal.Title className='title'>Edit Customer</Modal.Title>
                    <Button variant="basic" className='ms-auto px-0' onClick={onClose}><XCircleFillIcon size={24} /></Button>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}
                        <Form.Group className="mb-3" controlId="company">
                            <Form.Label>Select Company<span className='input-required-icn'>*</span></Form.Label>
                            <Select
                                options={companiesList}
                                value={selectedCompany}
                                onChange={(selectedOption) => setSelectedCompany(selectedOption)}
                                className="react-select-container"
                                classNamePrefix="react-select"
                            />
                            {validationError.id && <p className="error-message position-absolute">{validationError.id}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter name"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.name && <p className="error-message position-absolute">{validationError.name}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email Address<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter email"
                                value={formData.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.email && <p className="error-message position-absolute">{validationError.email}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Phone Number<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                placeholder="Enter phone number"
                                value={formData.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.phone && <p className="error-message position-absolute">{validationError.phone}</p>}
                        </Form.Group>
                        {/* <p style={{
                            color: '#323232',
                            fontSize: '14px',
                            fontWeight: '500',
                            marginBottom: '0.25rem'
                        }}>Select Driving License</p>
                        <Card className='d-flex flex-row align-items-center border-0 mb-3 p-0'>
                            {(imageSrc || selectedFiles.length > 0) && <Card.Header className='position-relative p-0 border-0'>
                                <Card.Img style={{
                                    width: '6.25rem',
                                    height: '6.25rem'
                                }}
                                    src={imageSrc}
                                    className='object-fit-cover cursor-pointer'
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevents infinite loop if the fallback also fails
                                        e.target.src = '';
                                    }}
                                    onClick={handleDownloadLicense}
                                />
                                <span className='d-inline-flex justify-content-center align-items-center position-absolute bottom-0 end-0 rounded-circle m-1'
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: 'white',  // Corrected background-color syntax
                                        width: '24px',
                                        height: '24px'
                                    }}>
                                    <TrashIcon size={14} onClick={handleRemoveLicense} color='red' />
                                </span>
                            </Card.Header>}
                            <Card.Body className='p-0'>
                                <Form.Control
                                    id="fileInput"
                                    type="file"
                                    name='driving_license'
                                    className='d-none'
                                    accept="image/jpeg, image/png, image/jpg, application/pdf"
                                    onChange={handleFileSelect} // Handle file change in this function
                                    webkitdirectory
                                />
                                <Button
                                    variant="primary-outline"
                                    className='ms-3'
                                    onClick={handleBrowseClick}
                                >
                                    Browse Files
                                </Button>
                            </Card.Body>
                        </Card> */}
                    </Modal.Body>
                    <Modal.Footer className='border-0 pt-0'>
                        <Button variant="-primary" type='submit'>
                            Save
                        </Button>
                        <Button variant="primary-outline" onClick={onClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    );
};

export default EditCustomer;
