import * as React from "react";

const Eye = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 16;
    const defaultHeight = 12;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M15.687 5.696c-.023-.051-.57-1.267-1.78-2.476C12.29 1.604 10.25.75 8 .75s-4.29.854-5.906 2.47C.884 4.43.337 5.645.312 5.696a.755.755 0 0 0 0 .609c.023.051.57 1.266 1.78 2.476C3.71 10.396 5.75 11.25 8 11.25c2.25 0 4.29-.854 5.905-2.47 1.21-1.209 1.757-2.424 1.78-2.475a.755.755 0 0 0 .002-.61ZM12.81 7.758C11.466 9.08 9.849 9.75 7.999 9.75c-1.849 0-3.466-.67-4.806-1.992A8.147 8.147 0 0 1 1.845 6a8.153 8.153 0 0 1 1.348-1.757C4.533 2.921 6.15 2.25 8 2.25c1.85 0 3.466.67 4.807 1.993.527.522.98 1.113 1.348 1.757a8.153 8.153 0 0 1-1.348 1.758h.002ZM7.999 3.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Zm0 4a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z"
            />
        </svg>
    );
}

export default Eye;
