import React, { useState } from 'react';
import './LoginPage.scss';
import { LoginLogo } from '../../../components';
import LoginForm from './components/LoginForm';
import OtpVerificationForm from './components/OtpVerificationForm';
import { Row, Col } from 'react-bootstrap';

const LoginPage = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
        <div className='login-wrapper d-flex justify-content-center align-items-center'>
            <Row className='login m-0'>
                <Col lg={6} className='px-0'>
                    <LoginLogo />
                </Col>
                <Col lg={6} className='px-0'>
                    {isLoggedIn ? (
                        <OtpVerificationForm setIsLoggedIn={setIsLoggedIn} />
                    ) : (
                        <LoginForm setIsLoggedIn={setIsLoggedIn} />
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default LoginPage;
