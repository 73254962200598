import { useState } from 'react';
import { Button, Form, Col, ListGroup, ProgressBar } from 'react-bootstrap';
import CloudArrowUpIcon from '../../../../assets/icons/CloudArrowUp';
import XcircleIcon from '../../../../assets/icons/XCircle';
import CheckCircleIcon from '../../../../assets/icons/CheckCircle';
import { useUploadFileMutation } from '../api/uploadApi';
import { Loader } from '../../../../components';

const DragDropFileZone = ({ paginationState, setToastMessage }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [uploadFileApi] = useUploadFileMutation();

    const validateFiles = (files) => {
        return files.filter(file => file.type === 'text/csv');
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        // Remove me if you want to upload multi files.
        if (droppedFiles?.length > 1) {
            setToastMessage({
                message: "You can select only one file at a time",
                variant: "error"
            });
            return;
        }
        uploadFiles(Array.from(e.dataTransfer.files));
    };

    const cancelFileSelection = () => setSelectedFiles([]);
    const handleFileSelect = (e) => {
        uploadFiles(Array.from(e.target.files));
        // Reset the file input so that selecting the same file again triggers onChange
        e.target.value = '';
    };
    const handleBrowseClick = () => document.getElementById('fileInput').click();

    const uploadFiles = (files) => {
        // Remove me if you want to upload multi files.
        if (selectedFiles?.length > 0) {
            setToastMessage({
                message: "You can select only one file at a time",
                variant: "error"
            });
            return;
        }
        const validFiles = validateFiles(files);
        if (validFiles.length !== files.length) {
            setToastMessage({
                message: "Only .csv files are allowed",
                variant: "error"
            });
            return;
        }
        setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);

        files.forEach((file, index) => {
            const reader = new FileReader();
            reader.onloadstart = () => {
                setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: 0,
                }));
            };

            reader.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: percentCompleted,
                    }));
                }
            };

            reader.onloadend = () => {
                setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: 100,
                }));
            };

            reader.readAsDataURL(file);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setShowLoader(true);
            const formData = new FormData();
            formData.append('csvfile', selectedFiles[0]);

            await uploadFileApi(formData).unwrap();
            paginationState.setPagination({
                ...paginationState.pagination,
                pageIndex: 0
            });
            setSelectedFiles([]);
            setToastMessage({
                message: "File uploaded successfully",
                variant: "success"
            });
        } catch (err) {
            setToastMessage({
                message: err?.data?.message || err?.data?.error || "Failed to upload a file",
                variant: "error"
            });
        }
        setShowLoader(false);
    };

    return (
        <>
            <Col className='mb-3 mb-md-0'>
                <div className="drag-drop-zone d-flex justify-content-center align-items-center">
                    <div
                        className="ddz_container d-flex justify-content-around justify-content-sm-center align-items-center px-3"
                        onDrop={handleFileDrop}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <div className="ddz_container--icon">
                            <CloudArrowUpIcon size={55} />
                        </div>
                        <div className="ddz_container--body">
                            <div className="upload-text mb-1">
                                <span className="fw-medium">Drag & Drop your file(s) here</span>
                                <br />
                                or
                            </div>
                            <Button variant="primary-outline" className="bg-transparent" onClick={handleBrowseClick}>
                                Browse Files
                            </Button>
                            <Form.Control id="fileInput" type="file" name='csvfile' style={{ display: "none" }} accept=".csv" onChange={handleFileSelect} />
                        </div>
                    </div>
                </div>
            </Col>
            <Col className='mb-3 mb-md-0 position-relative'>
                {/* Show Loader */}
                {showLoader && <Loader />}
                {selectedFiles?.length > 0 && (
                    <div className="ddz_uploadedfiles">
                        <ListGroup variant="flush" className='mb-3'>
                            {selectedFiles.map((file, index) => (
                                <ListGroup.Item key={index} className="d-flex flex-column flex-sm-row justify-content-between bg-transparent border-0 p-0">
                                    <span className='quaternary-text me-3'>{file.name}</span>
                                    <ProgressBar now={uploadProgress[file.name] || 0} className="flex-grow-1 flex-shrink-0 me-3 mb-2 mb-sm-0" />
                                    <span className='actions d-inline-flex'>
                                        <span className="me-1"><CheckCircleIcon size={18} /></span>
                                        <span onClick={cancelFileSelection}><XcircleIcon size={18} /></span>
                                    </span>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        <Button variant="-primary" onClick={handleSubmit}>Submit</Button>
                    </div>
                )}
            </Col>
        </>
    );
};

export default DragDropFileZone;