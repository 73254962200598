import * as React from "react";

const CheckMark = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 16;
    const defaultHeight = 16;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M8 .938a7.07 7.07 0 0 1 2.76.544 7.005 7.005 0 0 1 2.303 1.53l-.792.79a5.737 5.737 0 0 0-1.898-1.265A6.042 6.042 0 0 0 8 2.063a6.086 6.086 0 0 0-3.129.843 5.91 5.91 0 0 0-2.215 2.215 6.086 6.086 0 0 0-.844 3.129c0 1.125.282 2.168.844 3.129a5.91 5.91 0 0 0 2.215 2.215c.961.562 2.004.844 3.129.844a6.086 6.086 0 0 0 3.129-.844 5.91 5.91 0 0 0 2.215-2.215 6.086 6.086 0 0 0 .668-4.535l.914-.897c.258.668.386 1.436.386 2.303a7.16 7.16 0 0 1-1.002 3.691 7.196 7.196 0 0 1-2.619 2.62A7.16 7.16 0 0 1 8 15.563a7.16 7.16 0 0 1-3.691-1.003 7.196 7.196 0 0 1-2.62-2.619A7.16 7.16 0 0 1 .687 8.25 7.16 7.16 0 0 1 1.69 4.559a7.197 7.197 0 0 1 2.62-2.62A7.16 7.16 0 0 1 8 .937Zm6.346 2.408.808.808-6.75 6.75-.404.387-.404-.387-2.813-2.812.809-.809L8 9.691l6.346-6.345Z"
            />
        </svg>
    );
}

export default CheckMark;