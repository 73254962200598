import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const customerReportApi = createApi({
    reducerPath: 'customerReportApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        customerReport: builder.mutation({
            query: (data) => ({
                url: 'customer-outstanding',
                method: 'POST',
                body: data,
                responseHandler: (response) => data.isExport ? response.blob() : response.json()
            })
        })
    })
});

export const { useCustomerReportMutation } = customerReportApi;