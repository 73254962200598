import * as React from "react";

const HandCoins = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 32;
    const defaultHeight = 32;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M16.011 7.173a4.501 4.501 0 0 1 6.896-3.478.5.5 0 0 1-.07.875A6.508 6.508 0 0 0 19 10.5c0 .146 0 .293.015.436a.5.5 0 0 1-.75.47 4.5 4.5 0 0 1-2.254-4.233ZM30 20.076a3.058 3.058 0 0 1-1.7 2.75l-.055.025-4.854 2.068a.876.876 0 0 1-.148.05l-8 2c-.08.02-.161.03-.243.031H2a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h3.586l2.828-2.829A3.977 3.977 0 0 1 11.243 14H17.5a3.501 3.501 0 0 1 3.406 4.306l5.23-1.202A3.077 3.077 0 0 1 30 20.076Zm-2 0a1.076 1.076 0 0 0-1.359-1.037l-.038.01-8.376 1.926a1.04 1.04 0 0 1-.223.025H14a1 1 0 1 1 0-2h3.5a1.5 1.5 0 1 0 0-3h-6.258a1.984 1.984 0 0 0-1.413.586L7 19.414V25h7.875l7.804-1.951 4.75-2.023a1.07 1.07 0 0 0 .571-.95ZM21 10.5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0Z"
            />
        </svg>
    );
}

export default HandCoins;