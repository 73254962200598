import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Loader } from '../../../../components';
import XCircleFillIcon from '../../../../assets/icons/XCircleFill';
import NoteIcon from '../../../../assets/icons/Note';
import { useCustomerCsvImportMutation } from '../api/customersApi';

const ImportCustomersCsv = ({ onClose, setToastMessage }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [csvImportApi] = useCustomerCsvImportMutation();

    const handleFileSelect = (e) => {
        uploadFiles(Array.from(e.target.files)[0]);
        e.target.value = '';
    };
    const handleBrowseClick = () => document.getElementById('fileInput').click();

    const uploadFiles = (file) => {
        if (file.type !== 'text/csv') {
            setToastMessage({
                message: "Only .csv files are allowed",
                variant: "error"
            });
            return;
        }
        setSelectedFiles([file]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setShowLoader(true);
            const formData = new FormData();
            formData.append('customer', selectedFiles[0]);

            await csvImportApi(formData).unwrap();

            setSelectedFiles([]);
            setToastMessage({
                message: "Your file has been successfully uploaded. We are processing it and will send the results to your email soon.",
                variant: "success"
            });
        } catch (err) {
            setToastMessage({
                message: err?.data?.message || err?.data?.error || "Failed to upload a file",
                variant: "error"
            });
        }
        setShowLoader(false);
        onClose();
    };

    return (
        <Modal show={true} onHide={onClose}>
            {showLoader && <Loader />}
            <Modal.Header>
                <Modal.Title className='title'>Import Customers</Modal.Title>
                <Button
                    variant="basic"
                    className='ms-auto px-0'
                    onClick={onClose}
                >
                    <XCircleFillIcon size={24} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Use <a href='/SAMPLE_CUSTOMERS.csv'>
                        <u className='primary-text link-offset-2'>
                            sample CSV
                        </u>
                    </a> to format your customer data.
                </p>
                <p>
                    Replace the example data and save as CSV.
                </p>
                <div className='d-flex flex-wrap align-items-center mb-2'>
                    <span className="fw-medium me-2">Select Customers CSV File :</span>
                    <Button
                        variant="primary-outline text-nowrap"
                        className="bg-transparent"
                        onClick={handleBrowseClick}
                    >
                        Browse Files
                    </Button>
                </div>
                {
                    selectedFiles?.length > 0 &&
                    <p
                        className='d-inline-flex border rounded-2 px-2 py-1 mb-0'
                        style={{ fontSize: "12px" }}>
                        <figure className='mb-0'><NoteIcon size={16} /></figure>
                        <span className='quaternary-text fw-medium ms-1'>{selectedFiles[0]?.name}</span>
                    </p>
                }
                <Form.Control id="fileInput" type="file" name='csvfile' style={{ display: "none" }} accept=".csv" onChange={handleFileSelect} />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="-primary"
                    type='submit'
                    disabled={selectedFiles?.length ? false : true}
                    onClick={handleSubmit}>
                    Upload
                </Button>
                <Button variant="primary-outline" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImportCustomersCsv;