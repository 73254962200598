import * as React from "react";

const Logout = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 17;
    const defaultHeight = 16;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M7.688 14.875a.937.937 0 0 1-.938.938h-5a.938.938 0 0 1-.938-.938V1.125A.937.937 0 0 1 1.75.187h5a.937.937 0 1 1 0 1.875H2.687v11.876H6.75a.938.938 0 0 1 .938.937Zm8.475-7.538-3.125-3.125A.94.94 0 1 0 11.71 5.54l1.524 1.522H6.75a.937.937 0 1 0 0 1.875h6.484l-1.525 1.525a.94.94 0 1 0 1.329 1.328l3.125-3.125a.937.937 0 0 0 0-1.328Z"
            />
        </svg>
    );
}

export default Logout;