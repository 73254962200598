import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include'
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials
      })
    }),
    verifyOtp: builder.mutation({
      query: (otp) => ({
        url: 'auth/verify-otp',
        method: 'POST',
        body: otp
      })
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'GET'
      })
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        body: data
      })
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: 'auth/reset-password',
        method: 'PATCH',
        body: data
      })
    })
  }),
});

export const { useLoginMutation, useVerifyOtpMutation, useLogoutMutation, useForgotPasswordMutation, useResetPasswordMutation } = loginApi;