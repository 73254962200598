import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import './HomePage.scss';
import { Card, Button } from 'react-bootstrap';
import { useRecentPaymentMutation, useWeeklyPaymentMutation, useCompanyAnalysisMutation, useLocationWeeklyAnalysisMutation } from './api/dashboardApi';
import { BarChart, Loader, ReactTable, DashboardHeaders } from '../../../components';
import { defaultAdminChartData, ADMIN_ROLE, USER_ROLE } from '../../../utils/constants';
import { formatChartInputData, formatDollarNumber } from '../../../utils/helpers';
import HandCoinsIcon from '.././../../assets/icons/HandCoins';
import HandCoinsDropIcon from '.././../../assets/icons/HandCoinsDrop';
import ChartBarIcon from '.././../../assets/icons/ChartBar';
import CurrencyDollarIcon from '.././../../assets/icons/CurrencyDollar';
import InvoiceIcon from '.././../../assets/icons/Invoice';
import CaretRightIcon from "../../../assets/icons/CaretRight";
import { useListCompanyMutation } from '../../admin/company/api/companyApi';

const HomePage = () => {
    const role = useSelector((state) => state.login?.role);

    const companyAnalysisColumns = useMemo(() => [
        { header: 'COMPANY NAME', accessorKey: 'name', },
        {
            header: 'REVENUE',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'PAYABLE',
            cell: ({ row }) => formatDollarNumber(row.original.payable)
        },
        {
            header: 'PROFIT',
            cell: ({ row }) => formatDollarNumber(row.original.profit)
        },
        {
            header: 'CASH IN',
            cell: ({ row }) => formatDollarNumber(row.original.cash_in)
        },
        {
            header: 'CASH OUT',
            cell: ({ row }) => formatDollarNumber(row.original.cash_out)
        },
    ], []);
    const locationWeeklyAnalysisColumns = useMemo(() => [
        {
            header: 'LOCATION NAME',
            cell: ({ row }) => {
                return (
                    <>
                        <div className='primary-text'>{row.original.location_name}</div>
                        <div className='text-muted fs-7'>{row.original.address}</div>
                    </>
                )
            }
        },
        {
            header: 'CASH IN',
            cell: ({ row }) => formatDollarNumber(row.original.cash_in)
        },
        {
            header: 'CASH OUT',
            cell: ({ row }) => formatDollarNumber(row.original.cash_out)
        },
        {
            header: 'TOTAL REVENUE',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'TOTAL PAYABLE',
            cell: ({ row }) => formatDollarNumber(row.original.payable_amount)
        },
        {
            header: 'PROFIT',
            cell: ({ row }) => formatDollarNumber(row.original.profit)
        },

    ], []);
    const [recentPaymentData, setRecentPaymentData] = useState({});
    const [recentPaymentDate, setRecentPaymentDate] = useState({});
    const [weeklyPaymentData, setWeeklyPaymentData] = useState(defaultAdminChartData);
    const [weeklyPaymentDate, setWeeklyPaymentDate] = useState({});
    const [companyAnalysisData, setCompanyAnalysisData] = useState([]);
    const [locationWeeklyAnalysisData, setLocationWeeklyAnalysisData] = useState([]);
    const [locationWeeklyAnalysisDate, setLocationWeeklyAnalysisDate] = useState({});
    const [companyAnalysisDate, setCompanyAnalysisDate] = useState({});
    const [companiesList, setCompaniesList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({ value: '', label: 'All Company' });
    const navigate = useNavigate();
    const [recentPaymentApi] = useRecentPaymentMutation();
    const [weeklyPaymentApi] = useWeeklyPaymentMutation();
    const [listCompanyApi] = useListCompanyMutation();
    const [companyAnalysisApi] = useCompanyAnalysisMutation();
    const [locationWeeklyAnalysisApi] = useLocationWeeklyAnalysisMutation();

    const fetchRecentPayment = async () => {
        try {
            const recentPayments = await recentPaymentApi({ selectedCompany: selectedCompany.value }).unwrap();
            if (recentPayments?.data?.results[0]) {
                const { cash_in, cash_out, payable, profit, revenue } = recentPayments.data.results[0];
                setRecentPaymentData({ cash_in, cash_out, payable, profit, revenue });
                if (recentPayments.data?.dateRange) {
                    setRecentPaymentDate(recentPayments.data.dateRange);
                }
            } else {
                setRecentPaymentData({});
                setRecentPaymentDate({});
            }
        } catch (error) {
            setRecentPaymentData({});
            setRecentPaymentDate({});
        }
    }

    const fetchWeeklyPayment = async () => {
        try {
            setShowLoader(true);
            const weeklyPayments = await weeklyPaymentApi({ selectedCompany: selectedCompany.value }).unwrap();
            const weeklyPaymentRes = weeklyPayments?.data;
            if (weeklyPaymentRes) {
                setWeeklyPaymentData(formatChartInputData(weeklyPaymentRes));
                setWeeklyPaymentDate({
                    from_date: weeklyPaymentRes[0]?.from_date,
                    to_date: weeklyPaymentRes[weeklyPaymentRes.length - 1]?.to_date
                });
            } else {
                setWeeklyPaymentData(defaultAdminChartData);
                setWeeklyPaymentDate({});
            }
        } catch (error) {
            setWeeklyPaymentData(defaultAdminChartData);
            setWeeklyPaymentDate({});
        }
        setShowLoader(false);
    }

    const fetchCompanyAnalysis = async () => {
        try {
            const companyAnalysis = await companyAnalysisApi({ selectedCompany: selectedCompany.value }).unwrap();
            if (companyAnalysis?.data?.results?.length) {
                setCompanyAnalysisData(companyAnalysis.data.results);
                setCompanyAnalysisDate(companyAnalysis.data.dateRange);
            } else {
                setCompanyAnalysisData([]);
                setCompanyAnalysisDate({});
            }
        } catch (error) {
            setCompanyAnalysisData([]);
            setCompanyAnalysisDate({});
        }
    }

    const fetchCompanyList = async () => {
        try {
            const companies = await listCompanyApi({}).unwrap();
            const companiesData = companies?.data;
            if (companiesData?.companies?.length) {
                const companiesArrList = companiesData.companies.map(cp => ({ value: cp.id, label: cp.name }));
                companiesArrList.sort((a, b) => a.label.localeCompare(b.label));
                companiesArrList.unshift({ value: '', label: 'All Company' });
                setCompaniesList(companiesArrList);
            }
        } catch (error) { }
    }

    const fetchLocationWeeklyAnalysis = async () => {
        try {
            const locationWeeklyAnalysis = await locationWeeklyAnalysisApi({}).unwrap();
            if (locationWeeklyAnalysis?.data?.results?.length) {
                setLocationWeeklyAnalysisData(locationWeeklyAnalysis.data.results);
                setLocationWeeklyAnalysisDate(locationWeeklyAnalysis.data.dateRange);
            }
        } catch (error) { }
    }

    useEffect(() => {
        if (role === ADMIN_ROLE) {
            fetchCompanyList();
        } else if (role === USER_ROLE) {
            fetchLocationWeeklyAnalysis();
        }
    }, []);

    useEffect(() => {
        fetchRecentPayment();
        fetchWeeklyPayment();

        // If the company is selected, we won't show the company analysis section
        if (selectedCompany.value) {
            setCompanyAnalysisData([]);
        } else if (role === ADMIN_ROLE) {
            fetchCompanyAnalysis();
        }
    }, [selectedCompany]);

    return (
        <>
            <div className="dashboard content__wrapper content__wrapper--bgtransparent">
                {/* Recent Payment Trends */}
                <div className='layout layout--spacing bg-white mb-3'>
                    <div className="layout__subheader d-flex flex-column flex-sm-row justify-content-between align-items-lg-center mb-3">
                        <DashboardHeaders
                            title="Recent Payment Trends"
                            dateRange={recentPaymentDate}
                        />
                        {role === ADMIN_ROLE && <Select
                            options={companiesList}
                            defaultValue={selectedCompany}
                            onChange={(selectedOption) => setSelectedCompany(selectedOption)}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />}
                    </div>
                    <div className="layout__body">
                        <div className={`recent-payments`}>
                            <Card className='p-0'>
                                <Card.Body>
                                    <Card.Title className='d-flex align-items-center'>
                                        <figure className="card-icon d-inline-flex justify-content-center align-items-center mb-0">
                                            <HandCoinsIcon size={32} color={"#5856D6"} />
                                        </figure>
                                        <span className="fw-semibold ms-2 fs-6">Total Revenue</span>
                                    </Card.Title>
                                    <Card.Text>
                                        <span className="primary-text fw-bold fs-5 mx-1">${recentPaymentData.revenue || 0}</span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className='p-0'>
                                <Card.Body>
                                    <Card.Title className='d-flex align-items-center'>
                                        <figure className="card-icon d-inline-flex justify-content-center align-items-center mb-0">
                                            <HandCoinsDropIcon size={32} color={"#5856D6"} />
                                        </figure>
                                        <span className="fw-semibold ms-2 fs-6">Total Payable</span>
                                    </Card.Title>
                                    <Card.Text>
                                        <span className="primary-text fw-bold fs-5 mx-1">${recentPaymentData.payable || 0}</span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className='p-0'>
                                <Card.Body>
                                    <Card.Title className='d-flex align-items-center'>
                                        <figure className="card-icon d-inline-flex justify-content-center align-items-center mb-0">
                                            <ChartBarIcon size={32} color={"#5856D6"} />
                                        </figure>
                                        <span className="fw-semibold ms-2 fs-6">Profit</span>
                                    </Card.Title>
                                    <Card.Text>
                                        <span className="primary-text fw-bold fs-5 mx-1">${recentPaymentData.profit || 0}</span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className='p-0'>
                                <Card.Body>
                                    <Card.Title className='d-flex align-items-center'>
                                        <figure className="card-icon d-inline-flex justify-content-center align-items-center mb-0">
                                            <CurrencyDollarIcon size={32} color={"#5856D6"} />
                                        </figure>
                                        <span className="fw-semibold ms-2 fs-6">Cash In</span>
                                    </Card.Title>
                                    <Card.Text>
                                        <span className="primary-text fw-bold fs-5 mx-1">${recentPaymentData.cash_in || 0}</span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className='p-0'>
                                <Card.Body>
                                    <Card.Title className='d-flex align-items-center'>
                                        <figure className="card-icon d-inline-flex justify-content-center align-items-center mb-0">
                                            <InvoiceIcon size={32} color={"#5856D6"} />
                                        </figure>
                                        <span className="fw-semibold ms-2 fs-6">Cash Out</span>
                                    </Card.Title>
                                    <Card.Text>
                                        <span className="primary-text fw-bold fs-5 mx-1">${recentPaymentData.cash_out || 0}</span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>

                {/* Weekly Payment Trends */}
                <div className='layout layout--spacing bg-white mb-3'>
                    <div className="layout__subheader d-flex justify-content-between align-items-sm-center mb-3">
                        <DashboardHeaders
                            title="Weekly Payment Trends"
                            dateRange={weeklyPaymentDate}
                        />
                    </div>
                    <div className="layout__body position-relative">
                        {showLoader && <Loader />}
                        <BarChart data={weeklyPaymentData} />
                    </div>
                </div>

                {/* Company Analysis (Admin only) */}
                {role === ADMIN_ROLE && companyAnalysisData?.length > 0 &&
                    <div className='layout layout--spacing bg-white mb-3'>
                        <div className="layout__subheader d-flex justify-content-between align-items-sm-center mb-3">
                            <DashboardHeaders
                                title="Company Analysis"
                                dateRange={companyAnalysisDate}
                            />
                        </div>
                        <div className="layout__body">
                            <ReactTable
                                data={companyAnalysisData} columns={companyAnalysisColumns}
                            />
                        </div>
                    </div>
                }

                {/* Location Weekly Analysis (User only) */}
                {role === USER_ROLE && locationWeeklyAnalysisData?.length > 0 &&
                    <div className='layout layout--spacing bg-white mb-3'>
                        <div className="layout__subheader d-flex justify-content-between align-items-md-center mb-3">
                            <DashboardHeaders
                                title="Location Weekly Analysis"
                                dateRange={locationWeeklyAnalysisDate}
                            />
                            <Button variant="basic" className='ms-auto my-1' onClick={() => navigate('/location-report')}>
                                <span className='me-1 primary-text'>View All</span>
                                <span className='back-icn'><CaretRightIcon size={10} /></span>
                            </Button>
                        </div>
                        <div className="layout__body">
                            <ReactTable
                                data={locationWeeklyAnalysisData} columns={locationWeeklyAnalysisColumns}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default HomePage;