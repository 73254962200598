import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useChangePasswordMutation } from '../api/profileApi';
import { changePasswordFormValidation } from '../../../../utils/formValidation';

const initialFormData = { password: "", new_password: "", confirm_password: "" };

const ChangePassword = ({ setShowLoader, setToastMessage }) => {
    const [formData, setFormData] = useState(initialFormData);
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [changePasswordApi] = useChangePasswordMutation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const validationResult = changePasswordFormValidation({ ...formData, [name]: value });
        if (!validationResult.errorMessage?.[name] && validationError[name]) {
            setErrorMessage('');
            setValidationError({ ...validationError, [name]: "" });
            return;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setErrorMessage('');
            const validationResult = changePasswordFormValidation(formData);
            if (validationResult.errorMessage) {
                setValidationError(validationResult.errorMessage);
                return;
            }

            setShowLoader(true);
            await changePasswordApi(validationResult.data).unwrap();
            setToastMessage({
                message: "Your password has been successfully changed",
                variant: "success"
            });
            setFormData(initialFormData);
        } catch (err) {
            setValidationError({});
            setErrorMessage(err?.data?.message || err?.data?.error || 'Failed to change password');
        }
        setShowLoader(false);
    };

    return (
        <>
            <div className="layout__subheader d-flex flex-column mb-3">
                <h3 className="layout__subheader--title fs-5 mb-0">Change Password</h3>
            </div>
            <div className="layout__body">
                {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}
                <Form className='form__change-password'>
                    <Form.Group className="mb-3" controlId="currentPassword">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="Enter Current Password"
                            value={formData.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {validationError.password && <p className="error-message position-absolute">{validationError.password}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="new_password"
                            placeholder="Enter New Password"
                            value={formData.new_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {validationError.new_password && <p className="error-message position-absolute">{validationError.new_password}</p>}
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="confirmNewPassword">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="confirm_password"
                            placeholder="Re-Enter New Password"
                            value={formData.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {validationError.confirm_password && <p className="error-message position-absolute">{validationError.confirm_password}</p>}
                    </Form.Group>
                    <div className='d-flex'>
                        <Button variant="primary-outline" className='me-2' onClick={() => {
                            setFormData(initialFormData);
                            setValidationError({});
                            setErrorMessage('');
                        }}>
                            Cancel
                        </Button>
                        <Button variant="-primary" type='submit' onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default ChangePassword;