import * as React from "react";

const Ban = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 16;
    const defaultHeight = 16;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M8 .938a7.16 7.16 0 0 1 3.691 1.001 7.196 7.196 0 0 1 2.62 2.62 7.16 7.16 0 0 1 1.002 3.691 7.16 7.16 0 0 1-1.003 3.691 7.196 7.196 0 0 1-2.619 2.62A7.16 7.16 0 0 1 8 15.563a7.16 7.16 0 0 1-3.691-1.003 7.196 7.196 0 0 1-2.62-2.619A7.16 7.16 0 0 1 .687 8.25 7.16 7.16 0 0 1 1.69 4.559a7.197 7.197 0 0 1 2.62-2.62A7.16 7.16 0 0 1 8 .937Zm0 1.125c-.738 0-1.441.123-2.11.369a5.872 5.872 0 0 0-1.81 1.02l8.63 8.806c.47-.551.833-1.16 1.09-1.828.259-.703.387-1.43.387-2.18a6.086 6.086 0 0 0-.843-3.129 6.266 6.266 0 0 0-2.215-2.215A6.085 6.085 0 0 0 8 2.062Zm-4.71 2.18A6.305 6.305 0 0 0 2.2 6.07a6.283 6.283 0 0 0-.388 2.18c0 1.125.282 2.168.844 3.129a6.266 6.266 0 0 0 2.215 2.215c.961.562 2.004.844 3.129.844.738 0 1.441-.118 2.11-.352a6.17 6.17 0 0 0 1.81-1.037L3.29 4.242Z"
            />
        </svg>
    );
}

export default Ban;