import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/constants';

export const customerLocationApi = createApi({
    reducerPath: 'customerLocationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        addCustomerLocation: builder.mutation({
            query: (data) => ({
                url: 'location/add',
                method: 'POST',
                body: data
            })
        }),
        listCustomerLocations: builder.mutation({
            query: (data) => ({
                url: `location/view/${data.customerId}?page_no=${data.page}&limit=${DEFAULT_PAGE_SIZE}`,
                method: 'GET'
            })
        }),
        editCustomerLocation: builder.mutation({
            query: (data) => ({
                url: 'location/edit',
                method: 'PATCH',
                body: data
            })
        })
    })
});

export const { useAddCustomerLocationMutation, useListCustomerLocationsMutation, useEditCustomerLocationMutation } = customerLocationApi;