import { useEffect, useMemo, useState } from "react";
import './LocationReportPage.scss';
import { defaultPreviousWeekDates, downloadBlobFile } from "../../../utils/helpers";
import { CustomerLocationFilters, Loader, ReactTable, BarChart, ToastNotification } from "../../../components";
import { useMyLocationsMutation } from "../myLocations/api/myLocationsApi";
import { useLocationReportMutation, useLocationIncomeMutation } from "./api/locationReport";
import { extractDate, formatChartInputData, formatDollarNumber } from "../../../utils/helpers";
import { useMachineReportMutation } from "../../admin/locationMachines/api/locationMachinesApi";
import { defaultAdminChartData } from "../../../utils/constants";
import FileCSVIcon from "../../../assets/icons/FileCSV";

const SubTable = ({ data, columns }) => {
    return (
        <ReactTable
            data={data}
            columns={columns}
        />
    );
};

const LocationReportPage = () => {
    const locationIncomeColumns = useMemo(() => [
        {
            header: 'LOCATION NAME', accessorKey: 'name',
            cell: ({ row }) => {
                return (
                    <>
                        <div className='primary-text'>{row.original.location_name}</div>
                        <div className='text-muted fs-7'>{row.original.address}</div>
                    </>
                )
            }
        },
        {
            header: 'TOTAL REVENUE', accessorKey: 'revenue',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'TOTAL PAYABLE', accessorKey: 'payable',
            cell: ({ row }) => formatDollarNumber(row.original.payable)
        },
        {
            header: 'PROFIT', accessorKey: 'profit',
            cell: ({ row }) => formatDollarNumber(row.original.profit)
        }
    ], []);
    const machineReportColumns = useMemo(() => [
        {
            header: 'MACHINE NAME', accessorKey: 'machine_name'
        },
        {
            header: 'REVENUE',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'CASH IN',
            cell: ({ row }) => formatDollarNumber(row.original.cash_in)
        },
        {
            header: 'CASH OUT',
            cell: ({ row }) => formatDollarNumber(row.original.cash_out)
        }
    ], []);
    const defaultDates = useMemo(() => defaultPreviousWeekDates(), []);
    const [dateRange, setDateRange] = useState([defaultDates.startDate, defaultDates.endDate]);
    const [locationsList, setLocationsList] = useState([]);
    const [locationIncomeData, setLocationIncomeData] = useState([]);
    const [toastMessage, setToastMessage] = useState({});
    const [machineData, setMachineData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [locationIncomeTotalsData, setLocationIncomeTotalsData] = useState({});
    const [showMachineLoader, setShowMachineLoader] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({ value: '', label: 'All Location' });
    const [locationReportData, setLocationReportData] = useState(defaultAdminChartData);
    // const [locationReportDate, setLocationReportDate] = useState({});
    const [myLocationsApi] = useMyLocationsMutation();
    const [locationReportApi] = useLocationReportMutation();
    const [locationIncomeApi] = useLocationIncomeMutation();
    const [machineReportApi] = useMachineReportMutation();


    const fetchLocationReport = async (filterPayload) => {
        try {
            setShowLoader(true);
            const locationReport = await locationReportApi(filterPayload).unwrap();
            if (locationReport?.data) {
                setLocationReportData(formatChartInputData(locationReport.data));
            } else {
                setLocationReportData(defaultAdminChartData);
            }
        } catch (error) {
            setLocationReportData(defaultAdminChartData);
        }
        setShowLoader(false);
    }

    const fetchLocationIncome = async (filterPayload) => {
        try {
            const locationIncome = await locationIncomeApi(filterPayload).unwrap();
            if (locationIncome?.type === 'text/csv') {
                downloadBlobFile(locationIncome, 'location-report.csv');
            } else {
                if (locationIncome?.data?.results) {
                    setLocationIncomeData(locationIncome.data.results);
                    if (locationIncome.data?.totals[0]?.revenue) {
                        const { profit, payable, revenue } = locationIncome.data.totals[0];
                        setLocationIncomeTotalsData({
                            name: "Totals",
                            profit: `$${profit}`,
                            payable: `$${payable}`,
                            revenue: `$${revenue}`,
                        });
                    }
                } else {
                    setLocationIncomeData([]);
                }
            }
        } catch (error) {
            setLocationIncomeData([]);
        }
    }

    const fetchMachineReport = async () => {
        try {
            setShowMachineLoader(true);
            const extractedDate = extractDate(dateRange[0], dateRange[1]);
            const machineReport = await machineReportApi({
                start_date: extractedDate.startDate,
                end_date: extractedDate.endDate,
                id: selectedRow
            }).unwrap();
            if (machineReport?.data?.results) {
                setMachineData(machineReport.data.results);
            }
        } catch (error) {
            setMachineData([]);
        }
        setShowMachineLoader(false);
    }

    const fetchLocations = async () => {
        try {
            const myLocations = await myLocationsApi({ page: '' }).unwrap();
            if (myLocations?.data?.locations?.length) {
                const locationsArrList = myLocations.data.locations.map(cp => ({ value: cp.id, label: cp.name }));
                locationsArrList.sort((a, b) => a.label.localeCompare(b.label));
                locationsArrList.unshift({ value: '', label: 'All Location' });
                setLocationsList(locationsArrList);
            }
        } catch (error) { }
    }

    useEffect(() => {
        fetchLocations();
        handleApply();
    }, []);
    useEffect(() => {
        if (selectedRow) {
            fetchMachineReport();
        }
    }, [selectedRow]);

    const handleApply = (isExport) => {
        const extractedDate = extractDate(dateRange[0], dateRange[1]);
        const filterPayload = {
            from_date: extractedDate.startDate,
            to_date: extractedDate.endDate,
            location_id: selectedLocation.value,
            isExport
        }
        if (!isExport) {
            fetchLocationReport(filterPayload);
        }
        fetchLocationIncome(filterPayload);
        setSelectedRow('');
    }

    const handleCsvDownload = () => {
        if (!locationIncomeData?.length) {
            setToastMessage({
                message: "No records available for export.",
                variant: "error"
            });
            return;
        }
        handleApply(true);
    }

    return (
        <div className="content__wrapper content__wrapper--bgwhite content__wrapper--spacing">
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className='layout bg-white mb-3'>
                <div className="layout__subheader d-flex flex-wrap align-items-center">
                    <h3 className="layout__subheader--title fs-5 me-3 my-1">
                        Location Report
                    </h3>
                    <div className='d-inline-flex ms-auto my-1'>
                        <span>Export :</span>
                        <figure className='ms-1 mb-0 cursor-pointer' onClick={handleCsvDownload}>
                            <FileCSVIcon />
                        </figure>
                    </div>
                </div>
                <div className="row mx-0">
                    <hr className='border border-dark-subtle mt-2' />
                </div>
                <div className="layout__body position-relative">
                    <CustomerLocationFilters
                        dateRangeState={{
                            dateRange, setDateRange
                        }}
                        listData={{
                            data: locationsList,
                            title: "Select Location"
                        }}
                        selectedListState={{
                            selectedListData: selectedLocation,
                            setSelectedListData: setSelectedLocation
                        }}
                        onApply={handleApply}
                    />
                    <div className="position-relative">
                        {showLoader && <Loader />}
                        <BarChart data={locationReportData} />
                    </div>

                    <div className="location-report-table position-relative mt-3">
                        {showMachineLoader && <Loader />}

                        <ReactTable
                            data={locationIncomeData}
                            columns={locationIncomeColumns}
                            subTable={{
                                table: SubTable,
                                selectedRow,
                                setSelectedRow,
                                data: machineData,
                                columns: machineReportColumns,
                                setData: setMachineData
                            }}
                            totals={locationIncomeTotalsData}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocationReportPage;