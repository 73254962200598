import * as React from "react";

const User = (props) => {
    const { size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 18;
    const defaultHeight = 17;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--outline"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 11.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM1.5 15.875C3.013 13.26 5.762 11.5 9 11.5c3.238 0 5.987 1.76 7.5 4.375"
            />
        </svg>
    );
}

export default User;