import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const customerLocationMappingApi = createApi({
    reducerPath: 'customerLocationMappingApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        listUnmappedLocation: builder.mutation({
            query: (data) => ({
                url: 'customer/unmap/location',
                method: 'GET'
            })
        }),
        assignLocationToCustomer: builder.mutation({
            query: (data) => ({
                url: 'customer/assign/location',
                method: 'POST',
                body: data
            })
        }),
    })
});

export const { useListUnmappedLocationMutation, useAssignLocationToCustomerMutation } = customerLocationMappingApi;