import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getExpandedRowModel } from '@tanstack/react-table';
import DotsThreeIcon from "../assets/icons/DotsThree";
import CaretRight from "../assets/icons/CaretRight";
import CaretLeft from "../assets/icons/CaretLeft";
import { Dropdown, Button } from 'react-bootstrap';
import TrashIcon from "../assets/icons/Trash";
import PlusCircleIcon from "../assets/icons/PlusCircle";
import MinusCircleIcon from "../assets/icons/MinusCircle";
import { generatePageNumbers } from '../utils/helpers';

const ReactTable = (props) => {
    let {
        data,
        columns,
        pageCount,
        paginationState,
        actionMenu,
        setSelectedRow,
        uploadPage,
        onClickRedirectRoute,
        subTable,
        totals
    } = props;

    const [showMenu, setShowMenu] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setShowMenu(null);
    }, [data]);

    const handleMenuClick = (event, row) => {
        event.stopPropagation();
        setShowMenu(showMenu === row.id ? null : row.id);
        setSelectedRow(row.original);
    };

    // Add a new column for the action button
    if (actionMenu?.length) {
        columns = [
            ...columns,
            {
                header: 'Actions',
                id: 'actions',
                className: 'text-end',
                cell: ({ row }) => (
                    <Dropdown show={showMenu === row.id} onToggle={() => setShowMenu(showMenu === row.id ? null : row.id)}>
                        <Dropdown.Toggle as="button" className='btn btn-action ms-auto p-2' onClick={(e) => handleMenuClick(e, row)}>
                            <DotsThreeIcon size={16} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {actionMenu.map((menu) => (
                                <Dropdown.Item key={menu.name} onClick={(e) => {
                                    setShowMenu(null);
                                    menu.action(e);
                                }} className='d-flex align-items-center primary-text'>
                                    {menu.icon}
                                    <span className="ms-2">
                                        {menu.name}
                                    </span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                ),
            }
        ];
    }

    // Set delete file icon for the latest uploaded file
    if (uploadPage && paginationState?.pagination?.pageIndex === 0) {
        columns = [
            ...columns,
            {
                header: '',
                id: 'del',
                cell: ({ row }) => (
                    <>
                        {
                            row.id === '0' &&
                            <Button variant="light">
                                <TrashIcon onClick={() => {
                                    uploadPage.setLatestFileId({
                                        id: row.original.id,
                                        name: row.original.original_name
                                    });
                                    uploadPage.setDeleteModal(true);
                                }} />
                            </Button>
                        }
                    </>
                ),
            }
        ];
    }

    // Set Expand icon for customer location report table
    if (subTable) {
        columns = [
            ...columns,
            {
                header: '', id: 'expand',
                cell: ({ row }) => {
                    return (
                        subTable.selectedRow === row.original.id ?
                            <MinusCircleIcon
                                color="#3547AC"
                                onClick={() => {
                                    subTable.setSelectedRow("");
                                    subTable.setData([]);
                                }}
                            /> :
                            <PlusCircleIcon
                                onClick={() => {
                                    subTable.setSelectedRow(row.original.id);
                                    subTable.setData([]);
                                    // row.toggleExpanded();
                                }}
                            />
                    );
                }
            }
        ];
    }

    // Open the customer location and machine information route when clicking on a table row.
    const handleRowClick = (rowId) => {
        if (onClickRedirectRoute) {
            return navigate(onClickRedirectRoute(rowId));
        }
    };

    const tableOptions = {
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        state: {},
    };

    if (subTable) {
        tableOptions.getExpandedRowModel = getExpandedRowModel();
    }
    if (pageCount && paginationState) {
        tableOptions.getPaginationRowModel = getPaginationRowModel();
        tableOptions.onPaginationChange = paginationState.setPagination;
        tableOptions.state.pagination = paginationState.pagination;
        tableOptions.pageCount = pageCount;
        tableOptions.manualPagination = true;
    }

    const table = useReactTable(tableOptions);

    // Check if the current page is the last page
    const isLastPage = pageCount ? table.getState().pagination.pageIndex === pageCount - 1 : true;

    // Create totals row
    const renderTotalsRow = () => (
        <tr className="table__body--row table__totals-row" style={{
            backgroundColor: "#e4e9fd7a"
        }}>
            {columns.map(column => (
                <td key={column.id} className={`table__body--td align-middle fw-bold ${column.className || ''}`}>
                    <span className='primary-text'>{totals[column.accessorKey] || ''}</span>
                </td>
            ))}
        </tr>
    );

    return (
        <>
            <div className="table-responsive-lg react-table mb-3">
                <table className='table'>
                    <thead className='table__head'>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr className='table__head--row' key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th className={`table__head--th ${header.column.columnDef.className || ''}`} key={header.id}>
                                        <div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody className='table__body'>
                        {table.getRowModel().rows.length > 0 ? (
                            <>
                                {table.getRowModel().rows.map(row => (
                                    <React.Fragment key={row.id}>
                                        <tr
                                            className={`table__body--row ${onClickRedirectRoute ? 'tr-hoverable cursor-pointer' : ''}`}
                                            onClick={onClickRedirectRoute ? () => handleRowClick(row.original.id) : null}
                                        >
                                            {row.getVisibleCells().map(cell => (
                                                <td
                                                    className={`table__body--td ${table.getRowModel().rows.length === 1 ? 'align-top' : 'align-middle'} ${cell.column.columnDef.className || ''}`} key={cell.id}
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                        {(subTable?.selectedRow === row.original.id && subTable?.data) && (
                                            <tr>
                                                <td className='px-3' colSpan={columns.length}>
                                                    <subTable.table
                                                        data={subTable.data}
                                                        columns={subTable.columns}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                                {isLastPage && totals && renderTotalsRow()}
                            </>
                        ) : (
                            <tr className='table__body--row'>
                                <td colSpan={columns.length} className="text-center text-muted">
                                    No Data Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {pageCount !== 0 && paginationState && (
                <div className='react-table-pagination d-flex justify-content-end'>
                    <div className="pagination ms-auto">
                        <button
                            className='pagination_btn pagination_btn--prev'
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}>
                            <CaretLeft size={14} />
                        </button>
                        {generatePageNumbers(table).map((pageNumber, index) => (
                            <button
                                key={index}
                                onClick={() => typeof pageNumber === 'number' && table.setPageIndex(pageNumber - 1)}
                                className={`pagination_btn ${pageNumber === table.getState().pagination.pageIndex + 1 ? 'active' : ''}`}
                                disabled={typeof pageNumber !== 'number'}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <button
                            className='pagination_btn pagination_btn--next'
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}>
                            <CaretRight size={14} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ReactTable;