import * as React from "react";

const ChartBar = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 32;
    const defaultHeight = 32;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M29 26a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2h1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8h2V11a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v14h2V5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v20h1a1 1 0 0 1 1 1Z"
            />
        </svg>
    );
}

export default ChartBar;