import React from 'react';

const PasswordToggle = ({ isChecked, onToggle, id }) => {
    return (
        <div className="toggle-switch-wrapper">
            <span className="toggle-switch__label toggle-switch__label--yes quaternary-text">Yes</span>
            <div className="toggle-switch">
                <input
                    id={id}
                    type="checkbox"
                    className="toggle-switch__input"
                    checked={isChecked}
                    onChange={onToggle}
                />
                <label htmlFor={id} className="toggle-switch__toggle">
                    <span className="toggle-switch__slider"></span>
                </label>
            </div>
            <span className="toggle-switch__label toggle-switch__label--no quaternary-text">No</span>
        </div>
    );
};

export default PasswordToggle;
