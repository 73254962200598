import * as React from "react";

const Machine = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 18;
    const defaultHeight = 18;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--outline"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M9 3.375V1.687M15.75 11.25c-1.024 3.585-5.78 4.839-6.622 5.034a.563.563 0 0 1-.256 0c-.841-.195-5.598-1.448-6.622-5.034V8.843a.563.563 0 0 1 .385-.534l6.187-2.062a.562.562 0 0 1 .356 0l6.187 2.062a.562.562 0 0 1 .385.534v2.407ZM9 11.812V6.218"
            />
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3.938 7.875V3.937a.563.563 0 0 1 .562-.562h9a.562.562 0 0 1 .563.563v3.937"
            />
        </svg>
    );
}

export default Machine;
