import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AddNewButton, Loader, ReactTable, ToastNotification } from '../../../components';
import './CustomerLocationPage.scss';
import { Button } from 'react-bootstrap';
import { CustomerInformation, AddNewCustomerLocation, EditCustomerLocation, ViewCustomerLocation } from './components';
import { useListCustomerLocationsMutation } from './api/customerLocationApi';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import PencilIcon from "../../../assets/icons/Pencil";
import EyeIcon from "../../../assets/icons/Eye";
import CaretLeftIcon from "../../../assets/icons/CaretLeft";
import MachineIcon from "../../../assets/icons/Machine";
import { usDateFormat } from '../../../utils/helpers';

const CustomerLocationPage = () => {
    const { id } = useParams();
    const columns = useMemo(() => [
        { header: 'PLACE OF ISSUE', accessorKey: 'place_of_issue', },
        { header: 'NAME', accessorKey: 'name', },
        { header: 'LICENSE NUMBER', accessorKey: 'license_number', },
        { header: 'CODE', accessorKey: 'code', },
        {
            header: 'CONTRACT START DATE',
            cell: ({ row }) => usDateFormat(row.original.contract_start_date)
        }
    ], []);
    const [selectedRow, setSelectedRow] = useState(null);
    const actionMenu = useMemo(() => [
        {
            name: "View",
            action: (event) => {
                event.stopPropagation();
                setViewModal(true);
            },
            icon: <EyeIcon size={18} />
        },
        {
            name: "Edit",
            action: (event) => {
                event.stopPropagation();
                setEditModal(true);
            },
            icon: <PencilIcon size={18} />
        },
        {
            name: "Machine",
            action: (event) => {
                event.stopPropagation();
                return navigate(`/customer/${id}/location/${selectedRow.id}/machine`);
            },
            icon: <MachineIcon size={18} />
        },
    ], [selectedRow]);
    const navigate = useNavigate();
    const [customerInfo, setCustomerInfo] = useState({});
    const [locationList, setLocationList] = useState([]);
    const [totalLocation, setTotalLocation] = useState(0);
    const [addNewModal, setAddNewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [toastMessage, setToastMessage] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [listCustomerLocationApi] = useListCustomerLocationsMutation();

    const fetchCustomerLocations = async () => {
        try {
            setShowLoader(true);
            const locations = await listCustomerLocationApi({
                page: pagination.pageIndex + 1,
                customerId: id
            }).unwrap();
            const locationsData = locations?.data;
            if (locationsData?.customer) {
                const { name, phone, email, companies } = locationsData.customer;
                setCustomerInfo({
                    "COMPANY": companies.name,
                    "NAME": name,
                    "PHONE NUMBER": phone,
                    "EMAIL": email
                })
            }
            if (locationsData?.location) {
                setLocationList(locationsData.location);
                setTotalLocation(locationsData.count);
            }
        } catch (error) {
            setLocationList([]);
            setTotalLocation(0);
        }
        setShowLoader(false);
    }

    const goBack = () => {
        navigate("/customers");
    };
    useEffect(() => {
        fetchCustomerLocations();
    }, [pagination]);

    return (
        <>
            {/* Show success toaster */}
            {
                toastMessage.message &&
                <ToastNotification
                    message={toastMessage.message}
                    variant={toastMessage.variant}
                    onClose={() => setToastMessage({})}
                />
            }
            <div className="content__wrapper content__wrapper--bgtransparent customer-location-page">
                {/* Customer location header */}
                <div className='layout layout--spacing bg-white mb-3'>
                    <div className="layout__subheader d-flex align-items-center mb-2">
                        <h3 className="layout__subheader--title mb-0">Customer Information</h3>
                        <Button
                            variant="primary-outline"
                            className='ms-auto my-1'
                            onClick={goBack}
                        >
                            <span className='back-icn'>
                                <CaretLeftIcon size={10} />
                            </span>
                            <span className='d-none d-sm-inline ms-1'>Back</span>
                        </Button>
                    </div>
                    <div className="row mx-0">
                        <hr />
                    </div>
                    <div className="layout__body">
                        <div className="customer-info">
                            {
                                Object.keys(customerInfo).map((key) => {
                                    return <CustomerInformation
                                        title={key}
                                        value={customerInfo[key]}
                                        key={key}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* Location Information */}
                <div className='layout layout--spacing bg-white position-relative'>
                    {/* Show Loader */}
                    {showLoader && <Loader />}
                    <div className='layout__subheader d-flex flex-wrap justify-content-between align-items-center'>
                        <h3 className="layout__subheader--title mb-0 me-2">Location Information</h3>
                        <AddNewButton
                            name={"Add New Location"}
                            handleClick={() => setAddNewModal(true)}
                        />
                    </div>

                    {/* Table for list of location */}
                    <div className="layout__body mt-3">
                        <ReactTable
                            columns={columns}
                            data={locationList}
                            pageCount={Math.ceil(totalLocation / DEFAULT_PAGE_SIZE)}
                            paginationState={{ pagination, setPagination }}
                            actionMenu={actionMenu}
                            setSelectedRow={setSelectedRow}
                            onClickRedirectRoute={(lId) => `/customer/${id}/location/${lId}/machine`}
                        />
                    </div>
                </div>
            </div>

            {/* Add New Location Modal */}
            {addNewModal &&
                <AddNewCustomerLocation
                    onClose={() => setAddNewModal(false)}
                    paginationState={{ pagination, setPagination }}
                    customer_id={id}
                    setToastMessage={setToastMessage}
                />
            }

            {/* Edit Customer Location Modal */}
            {editModal &&
                <EditCustomerLocation
                    selectedRow={selectedRow}
                    onClose={() => setEditModal(false)}
                    paginationState={{ pagination, setPagination }}
                    setToastMessage={setToastMessage}
                />
            }

            {/* View Customer Location Modal */}
            {viewModal &&
                <ViewCustomerLocation
                    selectedRow={selectedRow}
                    onClose={() => setViewModal(false)}
                />
            }
        </>
    );
};

export default CustomerLocationPage;