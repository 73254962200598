import * as React from "react";

const Pencil = (props) => {
    const { size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 13;
    const defaultHeight = 13;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--outline"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.2}
                d="M4.293 12.293H1.5a.5.5 0 0 1-.5-.5V9a.5.5 0 0 1 .146-.354l7.5-7.5a.5.5 0 0 1 .708 0l2.792 2.793a.5.5 0 0 1 0 .707l-7.5 7.5a.499.499 0 0 1-.353.147ZM7 2.793l3.5 3.5M4.468 12.261 1.032 8.825"
            />
        </svg>
    );
}

export default Pencil;