import * as React from "react";

const Trash = (props) => {
    const { size = "24", onClick, color } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 13;
    const defaultHeight = 14;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--outline"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
            onClick={onClick}
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.2}
                d="M12 3H1M5 6v4M8 6v4M11 3v9.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5V3M9 3V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v1"
            />
        </svg>
    );
}

export default Trash;
