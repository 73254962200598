import React, { useState, useEffect } from 'react';
import Logo from '../components/Logo';
import loginBgImg from '../assets/images/background/login-bg.jpg';
import logoImageWhite from '../assets/images/logo/logoWhite.svg';
import logoImage from '../assets/images/logo/logo.svg';

const LoginLogo = React.memo(() => {
    const [imageUrl, setImageUrl] = useState(window.innerWidth >= 992 ? logoImageWhite : logoImage);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setImageUrl(logoImageWhite);
            } else {
                setImageUrl(logoImage);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='login__img'>
            <img src={loginBgImg} alt="logo" className='d-none d-lg-flex' />
            <Logo imageUrl={imageUrl} altText="Logo" width="168px" height="auto" />
        </div>
    );
});

export default LoginLogo;