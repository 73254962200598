import * as React from "react";

const Note = (props) => {
    const { size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 16;
    const defaultHeight = 16;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                d="M5.25 6.75A.75.75 0 0 1 6 6h4a.75.75 0 1 1 0 1.5H6a.75.75 0 0 1-.75-.75Zm2 1.75H6A.75.75 0 0 0 6 10h1.25a.75.75 0 0 0 0-1.5Zm7-5.5v6.793a1.241 1.241 0 0 1-.366.884l-3.207 3.207a1.24 1.24 0 0 1-.884.366H3A1.25 1.25 0 0 1 1.75 13V3A1.25 1.25 0 0 1 3 1.75h10A1.25 1.25 0 0 1 14.25 3Zm-11 9.75H9v-3A.75.75 0 0 1 9.75 9h3V3.25h-9.5v9.5Zm8.688-2.25H10.5v1.438l1.438-1.438Z"
            />
        </svg>
    );
}

export default Note;