import * as React from "react";

const HandCoinsDrop = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 32;
    const defaultHeight = 32;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M15.989 24.828a4.501 4.501 0 0 1-6.897 3.477.5.5 0 0 1 .07-.875A6.507 6.507 0 0 0 13 21.5c0-.146 0-.293-.015-.436a.5.5 0 0 1 .75-.47 4.5 4.5 0 0 1 2.254 4.233ZM2 11.924a3.058 3.058 0 0 1 1.7-2.75l.055-.025L8.609 7.08a.867.867 0 0 1 .149-.05l8-2A.99.99 0 0 1 17 5h13a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-3.586l-2.828 2.829A3.978 3.978 0 0 1 20.758 18H14.5a3.501 3.501 0 0 1-3.406-4.306l-5.23 1.202A3.077 3.077 0 0 1 2 11.924Zm2 0a1.076 1.076 0 0 0 1.359 1.037l.038-.01 8.375-1.926a1.04 1.04 0 0 1 .224-.025H18a1 1 0 1 1 0 2h-3.5a1.5 1.5 0 1 0 0 3h6.258a1.984 1.984 0 0 0 1.413-.586L25 12.586V7h-7.875L9.321 8.951l-4.75 2.023a1.07 1.07 0 0 0-.571.95Zm7 9.576a4.5 4.5 0 1 0-9 0 4.5 4.5 0 0 0 9 0Z"
            />
        </svg>
    );
}

export default HandCoinsDrop;