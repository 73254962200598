import { useState, useMemo, useEffect } from 'react';
import './IncomeReportPage.scss';
import { Button } from 'react-bootstrap';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { ReactTable, Loader, ToastNotification, ReactRangeDatePicker } from '../../../components';
import { useIncomeReportMutation } from './api/incomeReportApi';
import { defaultPreviousWeekDates, extractDate, downloadBlobFile } from '../../../utils/helpers';
import { formatDollarNumber } from '../../../utils/helpers';
import FileCSVIcon from "../../../assets/icons/FileCSV";

const IncomeReportPage = () => {
    const defaultDates = useMemo(() => defaultPreviousWeekDates(), []);
    const columns = useMemo(() => [
        { header: 'LOCATION NAME', accessorKey: 'location_name' },
        { header: 'CUSTOMER NAME', accessorKey: 'name' },
        { header: 'PLACE OF ISSUE', accessorKey: 'place_of_issue', },
        {
            header: 'NET AMOUNT', accessorKey: 'amounts',
            cell: ({ row }) => formatDollarNumber(row.original.amounts)
        }
    ], []);
    const [dateRange, setDateRange] = useState([defaultDates.startDate, defaultDates.endDate]);
    const [incomeReportList, setIncomeReportList] = useState([]);
    const [totalsData, setTotalsData] = useState({});
    const [totalList, setTotalList] = useState(0);
    const [toastMessage, setToastMessage] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [incomeReportApi] = useIncomeReportMutation();

    const fetchIncomeReport = async (isExport) => {
        try {
            if (!dateRange[0] || !dateRange[1]) {
                setToastMessage({
                    message: "Select start and end date to apply date filter",
                    variant: "error"
                });
                return;
            }
            setShowLoader(true);

            const extractedDate = extractDate(dateRange[0], dateRange[1]);
            const incomes = await incomeReportApi({
                start_date: extractedDate.startDate,
                end_date: extractedDate.endDate,
                page_no: pagination.pageIndex + 1,
                limit: DEFAULT_PAGE_SIZE,
                isExport
            }).unwrap();

            if (incomes?.type === 'text/csv') {
                downloadBlobFile(incomes, 'income-report.csv');
            } else {
                const incomesData = incomes?.data;
                if (incomesData?.results) {
                    setIncomeReportList(incomesData?.results);
                    setTotalList(incomesData.count);
                    if (incomesData?.totals?.amounts) {
                        setTotalsData({
                            location_name: "Totals",
                            amounts: `$${incomesData.totals.amounts}`
                        });
                    }
                }
            }
        } catch (error) {
            setIncomeReportList([]);
            setTotalList(0);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchIncomeReport();
    }, [pagination]);

    const handleCsvDownload = () => {
        if (!incomeReportList?.length) {
            setToastMessage({
                message: "No records available for export.",
                variant: "error"
            });
            return;
        }
        fetchIncomeReport(true);
    }

    return (
        <>
            {/* Show success toaster */}
            {
                toastMessage.message &&
                <ToastNotification
                    message={toastMessage.message}
                    variant={toastMessage.variant}
                    onClose={() => setToastMessage({})}
                />
            }
            <div className='layout-wrapper layout-income-report position-relative'>
                <div className="layout mt-3">
                    <div className="layout__subheader d-flex flex-wrap align-items-center">
                        <h3 className="layout__subheader--title fs-5 me-3 my-1">
                            Income Report
                        </h3>
                        <div className='d-inline-flex ms-auto my-1'>
                            <span>Export :</span>
                            <figure
                                className='ms-1 mb-0 cursor-pointer'
                                onClick={handleCsvDownload}
                            >
                                <FileCSVIcon />
                            </figure>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <hr className='border border-dark-subtle mt-2' />
                    </div>
                    <div className="layout__subheader d-flex flex-column flex-md-row align-items-center">
                        <ReactRangeDatePicker
                            lable="Select Date Range"
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                        />
                        <Button variant="primary-outline" className="d-inline-flex bg-transparent ms-md-3 mt-3 mt-md-0 h-100" onClick={() => {
                            setPagination({
                                ...pagination,
                                pageIndex: 0
                            });
                        }}>
                            Apply
                        </Button>
                    </div>

                    <div className="layout__body position-relative mt-3">
                        {/* Show Loader */}
                        {showLoader && <Loader />}

                        {/* Table for income report */}
                        <ReactTable
                            columns={columns}
                            data={incomeReportList}
                            pageCount={Math.ceil(totalList / DEFAULT_PAGE_SIZE)}
                            paginationState={{ pagination, setPagination }}
                            totals={totalsData}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default IncomeReportPage;