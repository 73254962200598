import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';
import { loggedInUserRole } from '../utils/auth';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setUserRole, setWarningMessage, setUser } from '../features/admin/login/slices/loginSlice';
import { Header, AdminSidebar, UserSideBar } from './index';
import { ADMIN_ROLE } from '../utils/constants';
import { useProfileInfoMutation } from '../features/common/profile/api/profileApi';
import BellIcon from '../assets/icons/Bell';

const AppMainLayout = () => {
    const [showMobileSidebar, setShowMobileSidebar] = useState(false);
    const dispatch = useDispatch();
    const [profileApi] = useProfileInfoMutation();
    const navigate = useNavigate();
    const warningMessage = useSelector((state) => state.login.warningMessage);

    const fetchProfileData = async () => {
        try {
            dispatch(setWarningMessage({ warningMessage: {} }));

            const profileData = await profileApi().unwrap();
            if (profileData?.data) {
                dispatch(setUser(profileData.data));
            }
            // Admin side, Message for unmapped locations found.
            if (profileData?.data?.unMappedLocations) {
                dispatch(setWarningMessage({
                    warningMessage: {
                        unMappedLocations: profileData.data.unMappedLocations
                    }
                }));
            }
        } catch (error) { }
    }
    useEffect(() => {
        fetchProfileData();
    }, []);

    const userRole = loggedInUserRole();
    if (!userRole) {
        Cookies.remove('token');
        window.location.href = '/login';
        return;
    }
    dispatch(setUserRole({ role: userRole }));

    const Sidebar = userRole === ADMIN_ROLE ? AdminSidebar : UserSideBar;

    return (
        <div className='app-wrapper'>
            <Header handleShow={() => setShowMobileSidebar(true)} />
            <div className="app-body-wrapper">
                <Sidebar
                    showMobileSidebar={showMobileSidebar}
                    handleClose={() => setShowMobileSidebar(false)}
                />
                <div className='app-body'>
                    {warningMessage?.unMappedLocations &&
                        <Alert className='d-flex mt-1' variant="danger">
                            <figure className='me-2 mb-0'>
                                <BellIcon size={30} />
                            </figure>
                            <p className='align-self-center mb-0'>
                                We found {warningMessage?.unMappedLocations} locations that are not currently associated with any customer. <span
                                    className='text-decoration-underline link-offset-2 cursor-pointer'
                                    onClick={() => navigate('/customer/location-mapping')}
                                >Click here</span> to assign a location to a customer.
                            </p>
                        </Alert>
                    }
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default AppMainLayout;