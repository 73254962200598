import { useState } from 'react';
import { LoginLogo, Loader, ToastNotification } from '../../../components';
import { Row, Col, Form, Button } from 'react-bootstrap';
import ArrowBackIcon from '../../../assets/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useForgotPasswordMutation } from '../../admin/login/api/loginApi';

const ForgotPasswordPage = () => {
    const [inputEmail, setInputEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [toastMessage, setToastMessage] = useState({});
    const [forgotPasswordApi] = useForgotPasswordMutation();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setInputEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!inputEmail) {
                setErrorMessage('Please Enter Email Address');
                return;
            }
            if (inputEmail.length > 100) {
                setErrorMessage('Invalid email');
                return;
            }

            setShowLoader(true);
            await forgotPasswordApi({ email: inputEmail }).unwrap();
            setToastMessage({
                message: "An email with a link to reset your password has been sent to you.",
                variant: "success"
            });
            setErrorMessage('');
            setInputEmail('');
        } catch (err) {
            setErrorMessage(err?.message || err?.data?.message || err?.data?.error || 'Forgot password request failed');
        }
        setShowLoader(false);
    };
    return (
        <div className='login-wrapper d-flex justify-content-center align-items-center'>
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <Row className='login m-0'>
                <Col lg={6} className='px-0'>
                    <LoginLogo />
                </Col>
                <Col lg={6} className='px-0'>
                    <div className='login__form d-flex flex-column justify-content-center h-100'>
                        {showLoader && <Loader />}

                        <h2 className='login__form--title fw-light mb-3'>Forgot Password?</h2>
                        <h3 className='login__form--text mb-4'>
                            Enter the email address associated with your account and we'll send you a link to reset your password.
                        </h3>

                        <Form className='mb-3' onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                <Form.Label className='mb-1'>Email Address</Form.Label>
                                {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}
                                <Form.Control
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={inputEmail}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Button variant="-primary" type="submit" className='mt-4 w-100'>
                                Send Reset Link
                            </Button>
                        </Form>

                        <p className='primary-text text-center'>
                            <span
                                className="text-decoration-underline link-offset-2 cursor-pointer"
                                onClick={() => navigate('/login')}
                            >
                                <ArrowBackIcon size={16} color={"#3547AC"} />
                                <span className='ms-1'>Back To Login</span>
                            </span>
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ForgotPasswordPage;