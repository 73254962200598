import { useEffect, useState, useMemo } from 'react';
import './LocationMachinesPage.scss';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMachineReportMutation } from './api/locationMachinesApi';
import { CustomerInformation } from '../customerLocation/components';
import { ReactTable, Loader, ToastNotification, ReactRangeDatePicker } from '../../../components';
import CaretLeftIcon from "../../../assets/icons/CaretLeft";
import { formatDollarNumber, defaultPreviousWeekDates, extractDate } from '../../../utils/helpers';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';

const LocationMachinesPage = () => {
    const defaultDates = useMemo(() => defaultPreviousWeekDates(), []);
    const columns = useMemo(() => [
        { header: 'MACHINE NAME', accessorKey: 'machine_name', },
        {
            header: 'REVENUE', accessorKey: 'revenue',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'CASH IN', accessorKey: 'cash_in',
            cell: ({ row }) => formatDollarNumber(row.original.cash_in)
        },
        {
            header: 'CASH OUT', accessorKey: 'cash_out',
            cell: ({ row }) => formatDollarNumber(row.original.cash_out)
        },
    ], []);
    const { id, customerId } = useParams();
    const [locationData, setLocationData] = useState({});
    const [machineData, setMachineData] = useState([]);
    const [allMachineCount, setAllMachineCount] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [dateRange, setDateRange] = useState([defaultDates.startDate, defaultDates.endDate]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [toastMessage, setToastMessage] = useState({});
    const [machineReportApi] = useMachineReportMutation();
    const navigate = useNavigate();

    const fetchMachineReport = async () => {
        try {
            if (!dateRange[0] || !dateRange[1]) {
                setToastMessage({
                    message: "Select start and end date to apply date filter",
                    variant: "error"
                });
                return;
            }
            setShowLoader(true);

            const extractedDate = extractDate(dateRange[0], dateRange[1]);
            const machineReport = await machineReportApi({
                id,
                page_no: pagination.pageIndex + 1,
                limit: DEFAULT_PAGE_SIZE,
                start_date: extractedDate.startDate,
                end_date: extractedDate.endDate
            }).unwrap();
            if (machineReport?.data?.location) {
                const { name, place_of_issue, license_number, code, customer } = machineReport.data.location;
                setLocationData({
                    "CUSTOMER NAME": customer?.name,
                    "LOCATION NAME": name,
                    "PLACE OF ISSUE": place_of_issue,
                    "LICENSE NUMBER": license_number,
                    "CODE": code
                });
            }
            if (machineReport?.data?.results) {
                setMachineData(machineReport.data.results);
                setAllMachineCount(machineReport.data.count);
            }
        } catch (error) {
            setMachineData([]);
            setAllMachineCount(0);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchMachineReport();
    }, [pagination]);

    return (
        <div className="content__wrapper content__wrapper--bgtransparent location-machines-page">
            {
                toastMessage.message &&
                <ToastNotification
                    message={toastMessage.message}
                    variant={toastMessage.variant}
                    onClose={() => setToastMessage({})}
                />
            }
            {/* Location header */}
            <div className='layout layout--spacing bg-white mb-3'>
                <div className="layout__subheader d-flex align-items-center mb-2">
                    <h3 className="layout__subheader--title mb-0">Location Information</h3>
                    <Button
                        variant="primary-outline"
                        className='ms-auto my-1'
                        onClick={() => navigate(`/customers/location/${customerId}`)}
                    >
                        <span className='back-icn'>
                            <CaretLeftIcon size={10} />
                        </span>
                        <span className='d-none d-sm-inline ms-1'>Back</span>
                    </Button>
                </div>
                <div className="row mx-0">
                    <hr />
                </div>
                <div className="layout__body">
                    <div className="location-info">
                        {
                            Object.keys(locationData).map((key) => {
                                return <CustomerInformation
                                    title={key}
                                    value={locationData[key]}
                                    key={key}
                                />
                            })
                        }
                    </div>
                </div>
            </div>

            <div className='layout-wrapper position-relative'>
                {showLoader && <Loader />}

                {/* Machine Information */}
                <div className='layout layout--spacing bg-white'>
                    <div className='layout__subheader d-flex flex-wrap justify-content-between align-items-center mb-3'>
                        <h3 className="layout__subheader--title mb-0 me-2">Machine Information</h3>
                    </div>
                    <div className="row">
                        <hr />
                    </div>
                    <div className="layout__subheader d-flex flex-column flex-md-row align-items-center">
                        <ReactRangeDatePicker
                            lable="Select Date Range"
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                        />
                        <Button
                            variant="primary-outline"
                            className="d-inline-flex bg-transparent ms-md-3 mt-3 mt-md-0 h-100"
                            onClick={() => {
                                setPagination({
                                    ...pagination,
                                    pageIndex: 0
                                });
                            }}>
                            Apply
                        </Button>
                    </div>
                    {/* Table for list of machine */}
                    <div className="layout__body mt-3">
                        <ReactTable
                            columns={columns}
                            data={machineData}
                            pageCount={Math.ceil(allMachineCount / DEFAULT_PAGE_SIZE)}
                            paginationState={{ pagination, setPagination }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default LocationMachinesPage;