import { combineReducers } from '@reduxjs/toolkit';
import login from '../features/admin/login/slices/loginSlice';
import { loginApi } from '../features/admin/login/api/loginApi';
import company from '../features/admin/company/slices/companySlice';
import { companyApi } from '../features/admin/company/api/companyApi';
import customers from '../features/admin/customers/slices/customersSlice';
import { customersApi } from '../features/admin/customers/api/customersApi';
import { customerLocationApi } from '../features/admin/customerLocation/api/customerLocationApi';
import { uploadApi } from '../features/admin/uploadData/api/uploadApi';
import { incomeReportApi } from '../features/admin/incomeReport/api/incomeReportApi';
import { customerReportApi } from '../features/admin/customerReport/api/customerReportApi';
import { locationReportApi } from '../features/admin/locationReport/api/locationReportApi';
import { locationMachinesApi } from '../features/admin/locationMachines/api/locationMachinesApi';

const rootReducer = combineReducers({
  login,
  [loginApi.reducerPath]: loginApi.reducer,
  company,
  [companyApi.reducerPath]: companyApi.reducer,
  customers,
  [customersApi.reducerPath]: customersApi.reducer,
  [customerLocationApi.reducerPath]: customerLocationApi.reducer,
  [uploadApi.reducerPath]: uploadApi.reducer,
  [incomeReportApi.reducerPath]: incomeReportApi.reducer,
  [customerReportApi.reducerPath]: customerReportApi.reducer,
  [locationReportApi.reducerPath]: locationReportApi.reducer,
  [locationMachinesApi.reducerPath]: locationMachinesApi.reducer
});

export default rootReducer;