import CalendarBlankIcon from '../assets/icons/CalendarBlank';
import { dateOptions } from '../utils/constants';
import { stringToDate } from '../utils/helpers';

const DashboardHeaders = ({ title, dateRange }) => {
    return (
        <h3 className="layout__subheader--title d-flex flex-column flex-md-row fs-5 mb-0">
            <span className="fw-bold me-3 mb-2 mb-md-0">
                {title}
            </span>
            {dateRange.from_date &&
                <span className="icon">
                    <CalendarBlankIcon size={20} />
                    <span className='ms-1 fw-normal fs-6'>
                        {
                            stringToDate(dateRange.from_date).toLocaleDateString('en-GB', dateOptions)} - {stringToDate(dateRange.to_date).toLocaleDateString('en-GB', dateOptions)
                        }
                    </span>
                </span>
            }
        </h3>
    )
}

export default DashboardHeaders;