import * as React from "react";

const DotsThree = (props) => {
    const { size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 15;
    const defaultHeight = 3;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                d="M7.25 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM1.25 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM13.25 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
            />
        </svg>
    );
}

export default DotsThree;