import Nav from 'react-bootstrap/Nav';
import { NavLink, Outlet } from 'react-router-dom';

const ReportsLayout = () => {
    return (
        <div className="content__wrapper content__wrapper--bgwhite content__wrapper--spacing">
            <div className='layout'>
                <div className="layout__header">
                    <h2 className="title">Reports</h2>
                </div>
                <div className="layout__body">
                    <Nav variant="underline" defaultActiveKey="/report/income">
                        <Nav.Item>
                            <Nav.Link as={NavLink} to='/report/income'>
                                Income Report
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={NavLink} to='/report/customer'>
                                Customer EFT
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={NavLink} to='/report/location'>
                                Location EFT
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={NavLink} to='/report/daily'>
                                Daily Report
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={NavLink} to='/report/machine'>
                                Machine Report
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
            <Outlet />
        </div>
    );
}

export default ReportsLayout;
