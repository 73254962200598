import * as React from "react";

const CurrencyDollar = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 32;
    const defaultHeight = 32;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="M20 19a2 2 0 0 1-2 2h-1v-4h1a2 2 0 0 1 2 2Zm9-3A13 13 0 1 1 16 3a13.014 13.014 0 0 1 13 13Zm-7 3a4 4 0 0 0-4-4h-1v-4h.5a2 2 0 0 1 2 2 1 1 0 0 0 2 0 4 4 0 0 0-4-4H17V8a1 1 0 0 0-2 0v1h-.5a4 4 0 1 0 0 8h.5v4h-1a2 2 0 0 1-2-2 1 1 0 0 0-2 0 4 4 0 0 0 4 4h1v1a1 1 0 0 0 2 0v-1h1a4 4 0 0 0 4-4Zm-9.5-6a2 2 0 0 0 2 2h.5v-4h-.5a2 2 0 0 0-2 2Z"
            />
        </svg>
    );
}

export default CurrencyDollar;