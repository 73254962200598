import { lazy } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Offcanvas, ListGroup } from 'react-bootstrap';
const GaugeIcon = lazy(() => import("../assets/icons/Gauge"));
const PresentationChartIcon = lazy(() => import("../assets/icons/PresentationChart"));
const GameControllerIcon = lazy(() => import("../assets/icons/GameController"));
const MapPinLineIcon = lazy(() => import("../assets/icons/MapPinLine"));

const UserSideBar = ({ showMobileSidebar, handleClose }) => {
    const handleLinkClick = () => handleClose();

    return (
        <>
            <aside className='sidebar'>
                <Offcanvas show={showMobileSidebar} onHide={handleClose} responsive="lg">
                    <Offcanvas.Header className='pb-0' closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='w-100'>
                        <Nav className='sidebar__menu'>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li">
                                    <NavLink to='/' className='list-group-item-link' onClick={handleLinkClick}>
                                        <figure className="link-icon-wrapper m-0">
                                            <GaugeIcon size={24} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>Dashboard</span>
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <NavLink to='/location-report' className='list-group-item-link' onClick={handleLinkClick}>
                                        <figure className="link-icon-wrapper m-0">
                                            <PresentationChartIcon size={24} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>Location Report</span>
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <NavLink to='/machine-report' className='list-group-item-link' onClick={handleLinkClick}>
                                        <figure className="link-icon-wrapper m-0">
                                            <GameControllerIcon size={24} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>Machine Report</span>
                                    </NavLink>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <NavLink to='/locations' className='list-group-item-link' onClick={handleLinkClick}>
                                        <figure className="link-icon-wrapper m-0">
                                            <MapPinLineIcon size={20} />
                                        </figure>
                                        <span className='sidebar__menu--title ms-2'>My Locations</span>
                                    </NavLink>
                                </ListGroup.Item>
                            </ListGroup>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </aside>
        </>
    )
}

export default UserSideBar;