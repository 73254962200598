import React, { useEffect, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import './UploadDataPage.scss';
import DragDropFileZone from './components/DragDropFileZone';
import { ReactTable, Loader, ToastNotification } from "../../../components";
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { useListFilesMutation } from './api/uploadApi';
import { formatDate } from '../../../utils/helpers';
import DeleteFile from './components/DeleteFile';
import { statusColorAndIcon, usDateFormat } from '../../../utils/helpers';

const UploadDataPage = () => {
    const columns = useMemo(() => [
        {
            header: 'UPLOADED DATE', accessorKey: 'createdAt',
            cell: ({ row }) => formatDate(row.original.createdAt)
        },
        {
            header: 'FILE NAME', accessorKey: 'original_name',
            cell: ({ row }) => {
                return (
                    <a
                        href={`${process.env.REACT_APP_UPLOAD_FILE_PATH}${row.original.name}`}
                        target="_blank"
                        className='link-offset-2 primary-text'
                    >
                        <u>{row.original.original_name}</u>
                    </a>
                )
            }
        },
        {
            header: 'DATE RANGE', accessorKey: 'dateRange',
            cell: ({ row }) => `${usDateFormat(row.original.from_date)} - ${usDateFormat(row.original.to_date)}`
        },
        {
            header: 'STATUS', accessorKey: 'status',
            cell: ({ row }) => {
                const status = row.original.status;
                const className = `status__${status.toLowerCase()}`;
                const statusDesign = statusColorAndIcon(status);
                const IconComponent = statusDesign.icon;

                return (
                    <>
                        <IconComponent color={statusDesign.color} size={14} />
                        <span className={`${className} ms-1`}>{status}</span>
                    </>
                );
            }
        },
        { header: 'UPLOADED BY', accessorKey: 'customer.name' }
    ], []);

    const [showLoader, setShowLoader] = useState(false);
    const [toastMessage, setToastMessage] = useState({});
    const [fileList, setFileList] = useState([]);
    const [totalFile, setTotalFile] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [latestFileId, setLatestFileId] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE
    })
    const [listFileApi] = useListFilesMutation();

    const fetchFileList = async () => {
        try {
            setShowLoader(true);
            const files = await listFileApi({
                page: pagination.pageIndex + 1
            }).unwrap();
            const filesData = files?.data;
            if (filesData?.fileRecords?.length) {
                setFileList(filesData.fileRecords);
                setTotalFile(filesData.count);
            } else {
                setFileList([]);
                setTotalFile(0);
            }
        } catch (error) {
            setFileList([]);
            setTotalFile(0);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchFileList();
    }, [pagination])

    return (
        <>
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className="content__wrapper content__wrapper--bgtransparent upload-data-page">

                <div className="layout layout--spacing bg-white mb-3">
                    <div className="layout__header">
                        <h2 className="title mb-0">Upload Data</h2>
                    </div>
                </div>
                <div className="layout layout--spacing">
                    <div className="layout__body">
                        <Row className='w-100 d-flex flex-column flex-md-row mx-auto'>
                            <DragDropFileZone
                                setToastMessage={setToastMessage}
                                paginationState={{ pagination, setPagination }}
                            />
                        </Row>
                    </div>
                </div>
                <div className="layout layout--spacing bg-white position-relative">
                    <div className="layout__body">
                        {/* Show Loader */}
                        {showLoader && <Loader />}
                        <ReactTable
                            data={fileList}
                            columns={columns}
                            pageCount={Math.ceil(totalFile / DEFAULT_PAGE_SIZE)}
                            paginationState={{ pagination, setPagination }}
                            uploadPage={{ setLatestFileId, setDeleteModal }}
                        />
                    </div>
                </div>
                {/* Delete File Modal */}
                {deleteModal &&
                    <DeleteFile
                        onClose={() => setDeleteModal(false)}
                        paginationState={{ pagination, setPagination }}
                        setToastMessage={setToastMessage}
                        selectedRow={latestFileId}
                    />}
            </div>
        </>
    )
}

export default UploadDataPage