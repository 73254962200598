import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_PAGE_SIZE } from "../../../../utils/constants";

export const locationReport = createApi({
    reducerPath: 'locationReport',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        locationReport: builder.mutation({
            query: (data) => ({
                url: 'location-report',
                method: 'POST',
                body: data
            })
        }),
        locationIncome: builder.mutation({
            query: (data) => ({
                url: 'location-income',
                method: 'POST',
                body: data,
                responseHandler: (response) => data.isExport ? response.blob() : response.json()
            })
        })
    })
})

export const { useLocationReportMutation, useLocationIncomeMutation } = locationReport;