import { useState, useMemo, useEffect } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { Loader, ReactTable, ToastNotification } from '../../../components';
import { useLocationReportMutation } from './api/locationReportApi';
import { formatDollarNumber, downloadBlobFile } from '../../../utils/helpers';
import FileCSVIcon from "../../../assets/icons/FileCSV";

const LocationReportPage = () => {
    const columns = useMemo(() => [
        { header: 'LOCATION NAME', accessorKey: 'location_name', },
        { header: 'CUSTOMER NAME', accessorKey: 'name', },
        {
            header: 'NET REVENUE', accessorKey: 'revenue',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'EFT', accessorKey: 'payable',
            cell: ({ row }) => formatDollarNumber(row.original.payable)
        },
        {
            header: 'NET AMOUNT', accessorKey: 'amounts',
            cell: ({ row }) => formatDollarNumber(row.original.amounts)
        }
    ], []);
    const [locationReportList, setLocationReportList] = useState([]);
    const [totalList, setTotalList] = useState(0);
    const [toastMessage, setToastMessage] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [totalsData, setTotalsData] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [locationReportApi] = useLocationReportMutation();

    const fetchLocationReport = async (isExport) => {
        try {
            setShowLoader(true);
            const apiData = await locationReportApi({
                page_no: pagination.pageIndex + 1,
                limit: DEFAULT_PAGE_SIZE,
                isExport
            }).unwrap();

            if (apiData?.type === 'text/csv') {
                downloadBlobFile(apiData, 'location-eft-report.csv');
            } else {
                const reportData = apiData?.data;
                if (reportData?.results) {
                    setLocationReportList(reportData?.results);
                    setTotalList(reportData?.count);
                    if (reportData?.totals?.amounts) {
                        const { revenue, payable, amounts } = reportData.totals;
                        setTotalsData({
                            location_name: "Totals",
                            revenue: `$${revenue}`,
                            payable: `$${payable}`,
                            amounts: `$${amounts}`,
                        });
                    }
                }
            }
        } catch (error) {
            setLocationReportList([]);
            setTotalList(0);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchLocationReport();
    }, [pagination]);

    const handleCsvDownload = () => {
        if (!locationReportList?.length) {
            setToastMessage({
                message: "No records available for export.",
                variant: "error"
            });
            return;
        }
        fetchLocationReport(true);
    }

    return (
        <>
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className="layout layout--spacing position-relative">
                {/* Show Loader */}
                {showLoader && <Loader />}

                <div className="layout__subheader d-flex flex-wrap align-items-center">
                    <h3 className="layout__subheader--title fs-5 me-3 my-1">
                        Location EFT Report
                    </h3>
                    <div className='d-inline-flex ms-auto my-1'>
                        <span>Export :</span>
                        <figure className='ms-1 mb-0 cursor-pointer' onClick={handleCsvDownload}>
                            <FileCSVIcon />
                        </figure>
                    </div>
                </div>

                <div className="row mx-0">
                    <hr className='border border-dark-subtle mt-2' />
                </div>

                {/* Table for list of location report */}
                <div className="layout__body">
                    <ReactTable
                        columns={columns}
                        data={locationReportList}
                        pageCount={Math.ceil(totalList / DEFAULT_PAGE_SIZE)}
                        paginationState={{ pagination, setPagination }}
                        totals={totalsData}
                    />
                </div>
            </div>
        </>
    )
}

export default LocationReportPage;