import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {},
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setUserRole: (state, action) => {
      state.role = action.payload.role;
    },
    setWarningMessage: (state, action) => {
      state.warningMessage = action.payload.warningMessage;
    }
  }
})

export const { setUser, setToken, setUserRole, setWarningMessage } = loginSlice.actions;

export default loginSlice.reducer;