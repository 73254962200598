import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { loginApi } from '../features/admin/login/api/loginApi';
import { companyApi } from '../features/admin/company/api/companyApi';
import { customersApi } from '../features/admin/customers/api/customersApi';
import { customerLocationApi } from '../features/admin/customerLocation/api/customerLocationApi';
import { uploadApi } from '../features/admin/uploadData/api/uploadApi';
import { incomeReportApi } from '../features/admin/incomeReport/api/incomeReportApi';
import { customerReportApi } from '../features/admin/customerReport/api/customerReportApi';
import { locationReportApi } from '../features/admin/locationReport/api/locationReportApi';
import { locationMachinesApi } from '../features/admin/locationMachines/api/locationMachinesApi';
import { handleUnauthorized } from '../utils/auth';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      handleUnauthorized,
      loginApi.middleware,
      companyApi.middleware,
      customersApi.middleware,
      customerLocationApi.middleware,
      uploadApi.middleware,
      incomeReportApi.middleware,
      customerReportApi.middleware,
      locationReportApi.middleware,
      locationMachinesApi.middleware,
    ),
});

export default store;