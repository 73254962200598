import Cookies from 'js-cookie';

export const isTokenExpired = () => {
    try {
        const token = Cookies.get('token');
        if (!token) return true;

        return false;
    } catch (error) {
        return true;
    }
};

export const loggedInUserRole = () => {
    try {
        const role = Cookies.get('role');
        return role;
    } catch (error) {
        return false;
    }
}

export const handleUnauthorized = (storeAPI) => (next) => (action) => {
    if (action.payload?.status === 401) {
        Cookies.remove('token');
        window.location.href = '/login';
        return;
    }
    return next(action);
};