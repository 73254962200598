import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_PAGE_SIZE } from "../../../../utils/constants";

export const uploadApi = createApi({
    reducerPath: 'uploadApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        uploadFile: builder.mutation({
            query: (data) => ({
                url: 'report/upload-csv',
                method: 'POST',
                body: data
            })
        }),
        listFiles: builder.mutation({
            query: (data) => ({
                url: `report/status?page_no=${data.page}&limit=${DEFAULT_PAGE_SIZE}`,
                method: 'GET',
            })
        }),
        deleteFile: builder.mutation({
            query: (data) => ({
                url: `report/delete/${data.id}`,
                method: 'DELETE'
            })
        })
    })
})

export const { useUploadFileMutation, useListFilesMutation, useDeleteFileMutation } = uploadApi;