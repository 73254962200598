import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        recentPayment: builder.mutation({
            query: (data) => ({
                url: 'dashboard/recent-payment',
                method: 'POST',
                body: { company_id: data.selectedCompany }
            })
        }),
        weeklyPayment: builder.mutation({
            query: (data) => ({
                url: 'dashboard/weekly-payment',
                method: 'POST',
                body: { company_id: data.selectedCompany }
            })
        }),
        companyAnalysis: builder.mutation({
            query: (data) => ({
                url: 'dashboard/company-analysis',
                method: 'POST',
                body: { company_id: data.selectedCompany }
            })
        }),
        locationWeeklyAnalysis: builder.mutation({
            query: (data) => ({
                url: 'dashboard/location-analysis',
                method: 'GET'
            })
        })
    })
});

export const { useRecentPaymentMutation, useWeeklyPaymentMutation, useCompanyAnalysisMutation, useLocationWeeklyAnalysisMutation } = dashboardApi;