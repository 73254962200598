import { Bar } from 'react-chartjs-2';
import { CDBContainer } from 'cdbreact';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const BarChart = ({ data }) => {
    return (
        <CDBContainer>
            <Bar
                data={data}
                options={{
                    responsive: true,
                    plugins: {
                        legend: { position: 'bottom' },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    return ` ${tooltipItem.dataset.label || ''}: ${tooltipItem.raw}`;
                                }
                            }
                        }
                    }
                }}
            />
        </CDBContainer>
    );
};

export default BarChart;