import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProfilePage.scss'
import { Row, Col, Button } from 'react-bootstrap';
import CaretLeftIcon from '../../../assets/icons/CaretLeft';
import ChangePassword from './components/ChangePassword';
import MyInformation from './components/MyInformation';
import { Loader, ToastNotification } from '../../../components';

const ProfilePage = () => {
    const location = useLocation();
    const from = location.state?.locationPath || "/";

    const [showLoader, setShowLoader] = useState(false);
    const [toastMessage, setToastMessage] = useState({});
    const navigate = useNavigate();

    return (
        <div className="content__wrapper content__wrapper--bgtransparent profile-page">
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className='layout layout--spacing bg-white mb-3'>
                <div className="layout__header d-flex justify-content-between align-items-sm-center">
                    <h2 className="title mb-0">My Profile</h2>
                    <Button variant="primary-outline">
                        <span className='back-icn'>
                            <CaretLeftIcon size={10} />
                        </span>
                        <span
                            className='ms-1'
                            onClick={() => navigate(from)}
                        >
                            Back
                        </span>
                    </Button>
                </div>
            </div>

            <div className='layout layout--spacing bg-white mb-3 position-relative'>
                {showLoader && <Loader />}
                <Row className='mx-0 my-3 gx-md-5'>
                    <Col>
                        {/* My Information Section */}
                        <MyInformation
                            setShowLoader={setShowLoader}
                            setToastMessage={setToastMessage}
                        />
                    </Col>
                    <Col md={1} className='my-5 my-md-0'>
                        <div className="seperator mx-auto opacity-25"></div>
                    </Col>
                    <Col>
                        {/* Change Password Section */}
                        <ChangePassword
                            setShowLoader={setShowLoader}
                            setToastMessage={setToastMessage}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ProfilePage;