import { useState, useMemo, useRef, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
// import CreatableSelect from 'react-select/creatable';
import { useAddCustomerLocationMutation } from '../api/customerLocationApi';
// import { useListUnmappedLocationMutation } from "../../locationMapping/api/customerLocationMappingApi"
import { Loader, ReactDatePicker } from '../../../../components';
import { customerLocationFormValidation } from '../../../../utils/formValidation';
import XCircleFillIcon from '../../../../assets/icons/XCircleFill';
import { extractStartDate, generateContractEndDate } from '../../../../utils/helpers';

const AddNewCustomerLocation = ({ onClose, paginationState, customer_id, setToastMessage }) => {
    const initialFormData = useMemo(() => {
        return { customer_id, place_of_issue: "", name: "", license_number: "", code: "", address: "" }
    }, []);
    const [formData, setFormData] = useState(initialFormData);
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    // const [allUnmappedLocations, setAllUnmappedLocations] = useState([]);
    // const [selectedLocation, setSelectedLocation] = useState(null);
    const firstInputRef = useRef(null);
    const [addCustomerLocationApi] = useAddCustomerLocationMutation();
    // const [listUnmappedLocationApi] = useListUnmappedLocationMutation();

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
        // fetchUnmappedLocations();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const validationResult = customerLocationFormValidation({ ...formData, [name]: value });
        if (!validationResult.errorMessage?.[name] && validationError[name]) {
            setErrorMessage('');
            setValidationError({ ...validationError, [name]: "" });
            return;
        }
    };

    // const handleCreateOption = async (inputValue) => {
    //     const newOption = { value: inputValue, label: inputValue };
    //     setSelectedLocation(newOption);
    //     setAllUnmappedLocations([...allUnmappedLocations, newOption]); // Update the options list
    //     setFormData(initialFormData);
    // }

    // const handleSelectOption = async (selectedOption) => {
    //     setSelectedLocation(selectedOption);
    //     // Prefill the input fields based on the selected option's data
    //     if (selectedOption?.license_number) {
    //         setFormData({
    //             ...formData,
    //             place_of_issue: selectedOption?.place_of_issue,
    //             license_number: selectedOption?.license_number,
    //             code: selectedOption?.code,
    //             address: selectedOption?.address
    //         })
    //     }
    // }

    // const fetchUnmappedLocations = async () => {
    //     try {
    //         setShowLoader(true);
    //         const unMappedLocations = await listUnmappedLocationApi().unwrap();
    //         if (unMappedLocations?.data.length) {
    //             const locationsArrList = unMappedLocations.data.map(loc => ({
    //                 value: loc.id,
    //                 label: loc.name,
    //                 place_of_issue: loc.place_of_issue,
    //                 license_number: loc.license_number,
    //                 code: loc.code,
    //                 address: loc.address
    //             }));
    //             locationsArrList.sort((a, b) => a.label.localeCompare(b.label));
    //             setAllUnmappedLocations(locationsArrList);
    //         }
    //     } catch (error) {
    //         setErrorMessage(error?.data?.message || error?.data?.error || "Failed to fetch list of locations");
    //     }
    //     setShowLoader(false);
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // formData.name = selectedLocation?.label;
            const validationResult = customerLocationFormValidation({ ...formData, contract_start_date: selectedStartDate });
            if (validationResult.errorMessage) {
                setErrorMessage('');
                setValidationError(validationResult.errorMessage);
                return;
            }

            setShowLoader(true);
            await addCustomerLocationApi({
                ...validationResult.data,
                customer_id,
                contract_start_date: extractStartDate(selectedStartDate)
            }).unwrap();
            paginationState.setPagination({
                ...paginationState.pagination,
                pageIndex: 0
            });
            setToastMessage({
                message: "Location created successfully",
                variant: "success"
            });
            onClose();
        } catch (err) {
            setValidationError({});
            setErrorMessage(err?.data?.message || err?.data?.error || 'Failed to add new customer location');
            setShowLoader(false);
        }
    };

    return (
        <>
            <Modal show={true} onHide={onClose}>
                {showLoader && <Loader />}
                <Modal.Header>
                    <Modal.Title className='title'>Add Location</Modal.Title>
                    <Button variant="basic" className='ms-auto px-0' onClick={onClose}><XCircleFillIcon size={24} /></Button>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}
                    <Form>
                        <Form.Group className="mb-3" controlId="place_of_issue">
                            <Form.Label>Place Of Issue<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="place_of_issue"
                                placeholder="Enter place of issue"
                                value={formData.place_of_issue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.place_of_issue && <p className="error-message position-absolute">{validationError?.place_of_issue}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Location Name<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter location name"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {/* <CreatableSelect
                                options={allUnmappedLocations}
                                value={selectedLocation}
                                onChange={handleSelectOption}
                                onCreateOption={handleCreateOption}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder="Select Location"
                                ref={firstInputRef}
                                formatCreateLabel={(inputValue) => inputValue}
                                noOptionsMessage={() => "No options available"}
                            /> */}

                            {validationError.name && <p className="error-message position-absolute">{validationError?.name}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="license_number">
                            <Form.Label>License Number<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="license_number"
                                placeholder="Enter license number"
                                value={formData.license_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.license_number && <p className="error-message position-absolute">{validationError?.license_number}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label>Code<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="code"
                                placeholder="Enter code"
                                value={formData.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.code && <p className="error-message position-absolute">{validationError?.code}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Address<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                placeholder="Enter address"
                                value={formData.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.address && <p className="error-message position-absolute">{validationError?.address}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="contract_start_date">
                            <Form.Label>Contract Start Date<span className='input-required-icn'>*</span></Form.Label>
                            <ReactDatePicker
                                lable=""
                                placeholder="Select Contract Start Date"
                                minDate={null}
                                dateState={{ selectedDate: selectedStartDate, setSelectedDate: setSelectedStartDate }}
                            />
                            {!selectedStartDate && validationError.contract_start_date && <p className="error-message position-absolute">{validationError?.contract_start_date}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="duration">
                            <Form.Label>Contract Duration (Months)<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="number"
                                name="contract_duration"
                                placeholder="Enter contract duration in months"
                                value={formData.contract_duration}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.contract_duration && <p className="error-message position-absolute">{validationError?.contract_duration}</p>}
                        </Form.Group>
                        {selectedStartDate && formData.contract_duration > 0 &&
                            <Form.Group className="mb-3 d-flex" controlId="contract_end_date">
                                <Form.Label className="fw-light me-2" style={{ fontSize: '14px' }}>Contract End Date  </Form.Label>
                                <p className="fw-light me-2" style={{ fontSize: '14px' }}>{generateContractEndDate(selectedStartDate, formData.contract_duration)}</p>
                            </Form.Group>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Button variant="-primary" type='submit' onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button variant="primary-outline" onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddNewCustomerLocation;