import { useState, useEffect, useMemo } from "react";
import { ReactTable, Loader } from "../../../components";
import { useMyLocationsMutation } from "./api/myLocationsApi";
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';

const MyLocationsPage = () => {
    const columns = useMemo(() => [
        { header: 'PLACE OF ISSUE', accessorKey: 'place_of_issue' },
        { header: 'LOCATION NAME', accessorKey: 'name' },
        { header: 'LICENSE NUMBER', accessorKey: 'license_number' },
        { header: 'CODE', accessorKey: 'code' },
        { header: 'ADDRESS', accessorKey: 'address' }
    ], []);
    const [myLocationsData, setMyLocationsData] = useState([]);
    const [totalLocations, setTotalLocations] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE
    });
    const [myLocationsApi] = useMyLocationsMutation();

    const fetchMyLocations = async () => {
        try {
            setShowLoader(true);
            const myLocations = await myLocationsApi({
                page: pagination.pageIndex + 1
            }).unwrap();
            if (myLocations?.data?.locations) {
                setMyLocationsData(myLocations.data.locations);
                setTotalLocations(myLocations.data.count);
            }
        } catch (error) { }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchMyLocations();
    }, [pagination]);
    return (
        <div className="content__wrapper content__wrapper--bgtransparent upload-data-page">
            <div className='layout layout--spacing bg-white mb-3 position-relative'>
                {/* Show Loader */}
                {showLoader && <Loader />}
                <div className="layout__subheader mb-1">
                    <h3 className="layout__subheader--title fs-5">
                        Location Information
                    </h3>
                </div>
                <div className="row mx-0">
                    <hr className='border border-dark-subtle mt-2' />
                </div>
                <div className="layout__body">
                    <ReactTable
                        data={myLocationsData}
                        columns={columns}
                        pageCount={Math.ceil(totalLocations / DEFAULT_PAGE_SIZE)}
                        paginationState={{ pagination, setPagination }}
                    />
                </div>
            </div>
        </div>
    )
}

export default MyLocationsPage;