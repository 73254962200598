import { useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import { Loader } from "../../../../components";
import { useDeleteCustomerMutation } from "../api/customersApi";
import XCircleFillIcon from '../../../../assets/icons/XCircleFill';

const DeleteCustomer = ({ selectedRow, onClose, paginationState, setToastMessage }) => {
    const [showLoader, setShowLoader] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');
    const [deleteCustomerApi] = useDeleteCustomerMutation();

    const handleDelete = async () => {
        try {
            if (selectedRow?.id) {
                setShowLoader(true);
                await deleteCustomerApi({ id: selectedRow.id }).unwrap();
                paginationState.setPagination({
                    ...paginationState.pagination,
                    pageIndex: 0
                });
            }
            setToastMessage({
                message: "Customer deleted successfully",
                variant: "success"
            });
            onClose();
        } catch (error) {
            setToastMessage({
                message: error?.data?.message || error?.data?.error || "Failed to delete this customer",
                variant: "error"
            });
            onClose();
            setShowLoader(false);
        }
    }

    return (
        <>
            <Modal show={true} onHide={onClose}>
                {showLoader && <Loader />}
                <Modal.Header>
                    <Modal.Title className='title'>Delete Customer</Modal.Title>
                    <Button variant="basic" className='ms-auto px-0' onClick={onClose}><XCircleFillIcon size={24} /></Button>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0">
                        Are you sure you want to delete <strong>{selectedRow?.name}</strong> customer?
                    </p>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Button variant="-primary" type='submit' onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="primary-outline" onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DeleteCustomer;