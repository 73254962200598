import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/constants';

export const companyApi = createApi({
    reducerPath: 'companyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        addCompany: builder.mutation({
            query: (data) => ({
                url: 'company/add',
                method: 'POST',
                body: data
            })
        }),
        listCompany: builder.mutation({
            query: (data) => ({
                url: data.page ? `company/all?page_no=${data.page}&limit=${DEFAULT_PAGE_SIZE}&search=${data.search}` : 'company/all',
                method: 'GET'
            })
        }),
        deleteCompany: builder.mutation({
            query: (data) => ({
                url: `company/delete/${data.id}`,
                method: 'DELETE'
            })
        }),
        editCompany: builder.mutation({
            query: (data) => ({
                url: 'company/edit',
                method: 'PATCH',
                body: data
            })
        })
    })
});

export const { useAddCompanyMutation, useListCompanyMutation, useDeleteCompanyMutation, useEditCompanyMutation } = companyApi;