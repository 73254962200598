import * as React from "react";

const CheckCircle = (props) => {
    const { size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 15;
    const defaultHeight = 15;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--outline"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.844 6.063 6.719 10 4.656 8.031"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.75 14.5a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
            />
        </svg>
    );
}

export default CheckCircle;
