import * as React from "react";

const FileArrowUp = (props) => {
    const { color = "currentColor", size = "24" } = props;

    // Set the default dimensions of the SVG (original viewBox dimensions)
    const defaultWidth = 18;
    const defaultHeight = 18;
    const aspectRatio = defaultWidth / defaultHeight;
    const viewBox = `0 0 ${defaultWidth} ${defaultHeight}`

    // Calculate height if only width is provided to maintain aspect ratio
    const height = size || size / aspectRatio;

    return (
        <svg
            className="svg_icon svg_icon--fill"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <path
                fill={color}
                d="m15.222 5.591-3.938-3.937a.845.845 0 0 0-.597-.248h-6.75a1.406 1.406 0 0 0-1.406 1.407v12.374a1.406 1.406 0 0 0 1.406 1.407h10.126a1.407 1.407 0 0 0 1.406-1.407v-9a.844.844 0 0 0-.247-.596Zm-2.355.034H11.25V4.008l1.617 1.617ZM4.22 14.906V3.094h5.343v3.375a.844.844 0 0 0 .844.843h3.375v7.594H4.22Zm7.065-5.097a.845.845 0 0 1-1.195 1.196l-.245-.247v2.18a.844.844 0 1 1-1.688 0v-2.18l-.247.247A.845.845 0 1 1 6.714 9.81l1.688-1.688a.844.844 0 0 1 1.195 0l1.688 1.687Z"
            />
        </svg>
    );
}

export default FileArrowUp;