import { useState, useMemo, useEffect } from "react";
import { CustomerLocationFilters, Loader, ReactTable, ToastNotification } from "../../../components";
import { defaultPreviousWeekDates, formatDollarNumber, extractDate, downloadBlobFile } from "../../../utils/helpers";
import { DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { useListCustomersMutation } from "../customers/api/customersApi";
import { useDailyReportMutation } from "./api/dailyReportApi";
import FileCSVIcon from "../../../assets/icons/FileCSV";

const DailyReportPage = () => {
    const columns = useMemo(() => [
        { header: 'LOCATION NAME', accessorKey: 'name' },
        {
            header: 'TOTAL REVENUE', accessorKey: 'revenue',
            cell: ({ row }) => formatDollarNumber(row.original.revenue)
        },
        {
            header: 'CASH IN', accessorKey: 'cash_in',
            cell: ({ row }) => formatDollarNumber(row.original.cash_in)
        },
        {
            header: 'CASH OUT', accessorKey: 'cash_out',
            cell: ({ row }) => formatDollarNumber(row.original.cash_out)
        }
    ], []);
    const defaultDates = useMemo(() => defaultPreviousWeekDates(), []);
    const [dateRange, setDateRange] = useState([defaultDates.startDate, defaultDates.endDate]);
    const [selectedCustomer, setSelectedCustomer] = useState({ value: '', label: 'All Customer' });
    const [dailyReportData, setDailyReportData] = useState([]);
    const [dailyReportCount, setDailyReportCount] = useState(0);
    const [toastMessage, setToastMessage] = useState({});
    const [customersList, setCustomersList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [totalsData, setTotalsData] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [listCustomersApi] = useListCustomersMutation();
    const [dailyReportApi] = useDailyReportMutation();

    const fetchCustomers = async () => {
        try {
            const customersList = await listCustomersApi().unwrap();
            const customers = customersList?.data?.customers;
            if (customers?.length) {

                const customersArrList = customers.map(cp => ({
                    value: cp.id,
                    label: `${cp.name} (${cp?.companies?.name})`
                }));

                customersArrList.sort((a, b) => a.label.localeCompare(b.label));
                customersArrList.unshift({ value: '', label: 'All Customer' });
                setCustomersList(customersArrList);
            }
        } catch (error) { }
    }

    const fetchDailyReport = async (isExport) => {
        try {
            setShowLoader(true);
            const extractedDate = extractDate(dateRange[0], dateRange[1]);
            const dailyReport = await dailyReportApi({
                customer_id: selectedCustomer.value,
                page_no: pagination.pageIndex + 1,
                limit: DEFAULT_PAGE_SIZE,
                from_date: extractedDate.startDate,
                to_date: extractedDate.endDate,
                isExport
            }).unwrap();

            if (dailyReport?.type === 'text/csv') {
                downloadBlobFile(dailyReport, 'daily-report.csv');
            } else {
                if (dailyReport?.data?.results) {
                    setDailyReportData(dailyReport.data.results);
                    setDailyReportCount(dailyReport.data.count);
                    if (dailyReport.data?.totals?.revenue) {
                        const { cash_in, cash_out, revenue } = dailyReport.data.totals;
                        setTotalsData({
                            name: "Totals",
                            cash_in: `$${cash_in}`,
                            cash_out: `$${cash_out}`,
                            revenue: `$${revenue}`,
                        });
                    }
                }
            }
        } catch (error) {
            setDailyReportData([]);
            setDailyReportCount(0);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchCustomers();
    }, []);
    useEffect(() => {
        fetchDailyReport();
    }, [pagination]);

    const handleCsvDownload = () => {
        if (!dailyReportData?.length) {
            setToastMessage({
                message: "No records available for export.",
                variant: "error"
            });
            return;
        }
        fetchDailyReport(true);
    }

    return (
        <>
            {/* Show success toaster */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}
            <div className="layout__subheader d-flex flex-wrap align-items-center mt-3">
                <h3 className="layout__subheader--title fs-5 me-3 my-1">
                    Daily Report
                </h3>
                <div className='d-inline-flex ms-auto my-1'>
                    <span>Export :</span>
                    <figure className='ms-1 mb-0 cursor-pointer' onClick={handleCsvDownload}>
                        <FileCSVIcon />
                    </figure>
                </div>
            </div>

            <div className="row mx-0">
                <hr className='border border-dark-subtle mt-2' />
            </div>

            <div className="layout__body mt-3">

                {/* Report filters */}
                <CustomerLocationFilters
                    dateRangeState={{
                        dateRange, setDateRange
                    }}
                    listData={{
                        data: customersList,
                        title: 'Select Customer'
                    }}
                    selectedListState={{
                        selectedListData: selectedCustomer,
                        setSelectedListData: setSelectedCustomer
                    }}
                    onApply={() => setPagination({ ...pagination, pageIndex: 0 })}
                />

                {/* Daily report data table */}
                <div className="position-relative mt-3">
                    {showLoader && <Loader />}
                    <ReactTable
                        data={dailyReportData}
                        columns={columns}
                        pageCount={Math.ceil(dailyReportCount / DEFAULT_PAGE_SIZE)}
                        paginationState={{ pagination, setPagination }}
                        totals={totalsData}
                    />
                </div>
            </div>
        </>
    )
}

export default DailyReportPage;